import React from "react"
import "../css/DFL.css"

export function DFL() {
  return (
    <div className="dfl-body">
      <div id="title" className="dfl-section">
        <h1 className="dfl-header">
          <i>Drug Facts</i>
        </h1>
      </div>
      {/* <!-- title --> */}

      <div id="active-ingredients" className="dfl-section">
        <div className="dfl-sub-header">
          <div>
            <i>Active ingredient (in each tablet)</i>
          </div>
          <div>
            <i>Purpose</i>
          </div>
        </div>

        <div className="dfl-content" style={{ display: "flex" }}>
          <div>Avanafil 50 mg</div>
          <div className="ellipses">
            ............................................................................................................................................
          </div>
          <div style={{ textAlign: "right" }}>Treats erectile dysfunction</div>
        </div>
      </div>
      {/* <!-- active-ingredients --> */}

      <div id="use" className="dfl-section">
        <div className="dfl-sub-header">
          <i>Use</i>
        </div>
        <div className="dfl-content">
          <ul className="dfl-unordered-list">
            <li>
              for men 18 years and older to help with recurring difficulties
              getting or keeping an erection hard enough for sex
            </li>
          </ul>
        </div>
      </div>
      {/* <!-- use --> */}

      <div
        id="warnings-dnu-ask-doctor-when-using-stop-use"
        className="dfl-section"
      >
        <h2 className="dfl-sub-header warnings">
          <i>Warnings</i>
        </h2>
        <div className="dfl-content">
          <h3><span className="highlight">Heart Alert</span></h3>
          <ul className="dfl-unordered-list">
            <li>
              <strong>
                <span className="highlight">erectile dysfunction may be a sign that you have a heart problem</span>
              </strong>
            </li>
            <li>
              <strong>
                this product is not safe for some men with heart problems
              </strong>
              <ul className="dfl-unordered-list">
                <li>
                  <strong>ask a doctor before use</strong> if you have ever had
                  any of these heart issues: heart attack or stroke; angina or
                  chest pain; heart procedure or heart surgery; irregular
                  heartbeat or heart valve issues; low blood pressure or
                  uncontrolled high blood pressure
                </li>

                <li>
                  <strong>do not use</strong> if you have ever had heart failure
                </li>
              </ul>
            </li>
            <li>
              <strong>if you experience signs of a heart attack</strong> during
              or after sex, <strong>stop use and seek medical help right away</strong>
              <ul className="dfl-unordered-list">
                <li>
                  signs include pain or pressure in the chest, jaw, neck or
                  arms; you get dizzy or feel faint; you have unexplained
                  indigestion, nausea or vomiting
                </li>
                <li>tell the doctor if you have taken this product</li>
              </ul>
            </li>
          </ul>

          <h3><span className="highlight">Nitrate Alert</span></h3>
          <ul className="dfl-unordered-list">
            <li>
              taking nitrate medicines or nitrite “poppers” with this product
              could cause a life-threatening drop in blood pressure and can lead
              to a stroke or heart attack
              <ul className="dfl-unordered-list">
                <li>
                  <strong>
                  <span className="highlight">NEVER use if you have ever been prescribed any nitrate
                    medicine</span></strong> for chest pain or heart failure
                </li>
                <li>
                  <strong>never take with nitrate medicines</strong> including
                  isosorbide or nitroglycerin
                </li>
                <li>
                  nitrates may be tablets, sprays, creams, patches or injectable
                  products. Ask your doctor or pharmacist if you are unsure if
                  you are taking a nitrate.
                </li>
                <li>
                  <strong>never use with nitrite “poppers”</strong> (street drugs such as amyl or
                  butyl nitrite)
                </li>
              </ul>
            </li>
          </ul>
        </div>
        {/* <!-- warnings --> */}

        <hr />

        <div className="dfl-sub-header">
          <strong>Do not use</strong> if you:
        </div>
        <div className="dfl-content">
          <ul className="dfl-unordered-list">
            <li>
              <strong><span className="highlight">have ever been prescribed any nitrate medicine</span></strong> for chest pain or heart failure, or if you use nitrite “poppers”
              <ul className="dfl-unordered-list">
                <li>
                <span className="highlight"><strong>never take with nitrate medicines</strong></span> including isosorbide or
                  nitroglycerin
                </li>
                <li>
                  <strong>never use with nitrite “poppers”</strong> (street drugs such as amyl or
                  butyl nitrite)
                </li>
                <li>
                  taking nitrate medicines or nitrite drugs with this product
                  could cause a life-threatening drop in blood pressure and can
                  lead to a stroke or heart attack
                </li>
              </ul>
            </li>

            <li>
              have been told by a doctor that it is not safe for you to have sex
            </li>

            <li>
              take any of these medicines
              <ul className="dfl-unordered-list">
                <li>itraconazole, ketoconazole (antifungals)</li>
                <li>clarithromycin, telithromycin (antibiotics)</li>
                <li>HIV medicine</li>
                <li>nefazodone (antidepressant)</li>
                <li>
                  riociguat or other medicines for pulmonary hypertension (high
                  blood pressure in the lungs)
                </li>
              </ul>
            </li>

            <li>ever had heart failure</li>
            <li>have severe kidney disease</li>
            <li>have severe liver disease</li>
            <li>have retinitis pigmentosa</li>
            <li>do not have erectile dysfunction as it will not work</li>
            <li>are under 18 years old</li>
            <li>
              have ever had an allergic reaction to any of the ingredients in
              this product
            </li>
          </ul>
        </div>
        {/* <!-- do-not-use-if --> */}

        <hr />

        <div className="dfl-sub-header">Ask a doctor before use if you:</div>
        <div className="dfl-content">
          <ul className="dfl-unordered-list">
            <li>
              breathlessness or chest pain with light or moderate physical
              activity
            </li>
            <li>
              ever had any of these heart issues, as this product is not safe
              for some men with heart problems
              <ul className="dfl-unordered-list">
                <li>heart attack or stroke</li>
                <li>angina or chest pain</li>
                <li>heart procedure or heart surgery</li>
                <li>irregular heartbeat, or heart valve issues</li>
                <li>low blood pressure or uncontrolled high blood pressure</li>
              </ul>
            </li>
            <li>dizziness or fainting when you stand up</li>
            <li>ever had a sudden loss of vision or hearing</li>
            <li>ever had an erection that has lasted more than 4 hours</li>
            <li>a deformed penis shape</li>
            <li>sickle cell anemia, multiple myeloma, or leukemia</li>
            <li>bleeding problems or have had stomach ulcers</li>
          </ul>
        </div>
        {/* <!-- ask-a-doctor --> */}

        <hr />

        <div className="dfl-sub-header">
          Ask a doctor or pharmacist before use if you are taking:
        </div>
        <div className="dfl-content">
          <ul className="dfl-unordered-list">
            <li>
              taking medicines for
              <ul className="dfl-unordered-list">
                <li>high blood pressure</li>
                <li>enlarged prostate (BPH)</li>
              </ul>
            </li>
            <li>using other treatments for erectile dysfunction</li>
            <li>
              unsure if you are taking a nitrate medicine. These may be tablets,
              sprays, creams, patches or injectable products.
            </li>
          </ul>
        </div>
        <hr />
        <div className="dfl-sub-header">When using this product:</div>
        <div className="dfl-content">
          <ul className="dfl-unordered-list">
            <li>
              you may experience these common side effects
              <ul className="dfl-unordered-list">
                <li>headache</li>
                <li>facial flushing</li>
                <li>back pain</li>
              </ul>
            </li>
            <li>do not have more than 3 alcoholic drinks</li>
            <li>
              this product does not protect against sexually transmitted
              diseases (STDs)
            </li>
          </ul>
        </div>
        {/* <!-- are-taking --> */}

        {/* <!-- when-using --> */}

        <hr />

        <div>
          <strong><span className="dfl-sub-header">Stop use and ask a doctor if</span></strong> you get any of these serious but uncommon
          symptoms. <strong>Seek medical help right away.</strong> Tell the doctor if you have
          taken this product.
        </div>
        <div className="dfl-content">
          <ul className="dfl-unordered-list">
            <li>
              <strong>signs of a heart attack</strong> during or after sex, such
              as
              <ul className="dfl-unordered-list">
                <li>pain or pressure in the chest, jaw, neck or arms</li>
                <li>get dizzy or feel faint</li>
                <li>unexplained indigestion, nausea or vomiting</li>
              </ul>
            </li>
            <li>an erection that lasts more than 4 hours</li>
            <li>sudden vision or hearing loss</li>
            <li>an allergic reaction to this product</li>
          </ul>
        </div>
        {/* <!-- stop-use-and-ask-doctor --> */}
      </div>
      {/* <!-- warnings-dnu-ask-doctor-when-using-stop-use --> */}

      <div id="keep-out-of-reach" className="dfl-section">
        <div className="dfl-content">
          <strong>Keep out of reach of children.</strong> In case of overdose,
          get medical help or contact a Poison Control Center right away.
        </div>
      </div>
      {/* <!-- keep-out-of-reach --> */}

      <div id="directions" className="dfl-section">
        <div className="dfl-sub-header">
          <i>Directions</i>
        </div>

        <div className="dfl-content">
          <table>
            <tbody>
              <tr>
                <td colSpan="2">men 18 years and older</td>
                <td colSpan="3">
                  <ul className="dfl-unordered-list">
                    <li>
                      take 1 tablet about 30 minutes before you plan to have sex
                      <ul className="dfl-unordered-list">
                        <li><strong>do not take</strong> more than 1 tablet a day</li>
                        <li>sexual stimulation is needed to get an erection</li>
                        <li>stop use and ask a doctor if this product does not work for you after trying one tablet per day on 3 different days</li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan="2">75 years or older</td>
                <td colSpan="3">
                  <strong>ask a doctor before use</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2">you do not have erectile dysfunction</td>
                <td colSpan="3">
                  <strong>do not use</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2">under 18 years of age</td>
                <td colSpan="3">
                  <strong>do not use</strong>
                </td>
              </tr>
              <tr>
                <td colSpan="2">women</td>
                <td colSpan="3">
                  <strong>do not use</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <!-- directions --> */}

      <div id="other-information" className="dfl-section">
        <div className="dfl-sub-header">Other Information</div>
        <div className="dfl-content">
          <ul className="dfl-unordered-list">
            <li>
              read and keep this carton for important information on safe use
            </li>
            <li>store between 68°F to 77°F (20°C to 25°C).</li>
            <li>keep out of the light</li>
          </ul>
        </div>
      </div>
      {/* <!-- other-information --> */}

      <div id="inactive-ingredients" className="dfl-section">
        <div className="dfl-content">
          <b><i>Inactive ingredients</i></b> calcium carbonate, ferric oxide yellow, fumaric acid,
          hydroxypropylcellulose, low substituted hydroxypropylcellulose,
          magnesium stearate, mannitol
        </div>
      </div>
      {/* <!-- inactive-ingredients --> */}

      <div id="questions" className="dfl-section">
        <div className="dfl-content">
          <b><i>Questions or comments?</i></b> call 1 xxx-xxx-xxxx M-F 8-5pm EST
        </div>
      </div>
      {/* <!-- questions --> */}
    </div>
  )
}
