import React from "react"
import Modal from "react-modal"
import { AssessmentButton } from "./AssessmentComponents"
import { CloseButton } from "../modal"
import useResponsive from "../responsive"
import "../css/Modal.css"

const modalStyle = {
  overlay: {
    backgroundColor: "var(--primary-focus)",
    zIndex: 5,
  },
  content: {
    background: "var(--tertiary)",
    height: "100%",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    maxHeight: "100vh",
    padding: 0,
    position: "fixed",
    overflowY: "auto",
  },
}

/**
 * Note: Adding '/* istanbul ignore next/' in styling variables as we do not need to test them for now
 */

export default function GenericModal({
  isOpen,
  buttonText,
  children,
  onRequestClose,
  stickyButton,
  title,
}) {
  const wrapperClass = isOpen ? "active" : "deactive"
  const isDesktop = useResponsive().desktop

  const titleContainerStyle = {
    alignItems: "center",
    backgroundColor: "var(--primary)",
    boxShadow: "0px 4px 4px 0px #00000040",
    display: "flex",
    marginBottom: "20px",
    paddingTop: "5px",
    paddingBottom: "5px",
    position: "sticky",
    top: 0,
  } /* istanbul ignore next */

  const titleStyle = {
    color: "var(--white)",
    display: "inline",
    fontSize: isDesktop ? "2.5rem" : "1.5rem",
    margin: "auto",
  } /* istanbul ignore next */

  const closeButtonStyle = {
    backgroundColor: "var(--secondary)",
    border: "none",
    borderRadius: "50%",
    margin: "25px",
    height: "35px",
    width: "35px",
  } /* istanbul ignore next */

  const contentContainer = {
    margin: "1rem",
    padding: isDesktop ? "2rem" : "1.5rem",
  } /* istanbul ignore next */

  const stickyButtonContainer = {
    backgroundColor: "var(--white)",
    bottom: "0",
    justifyContent: "center",
    padding: "1.5rem",
    position: "sticky",
  } /* istanbul ignore next */

  const stickyButtonStyle = {
    display: "block",
    margin: "auto",
  } /* istanbul ignore next */

  const handleAnimationModal = () => {
    // Animation for sliding out the modal and then closing it.
    const modal = document.getElementById("modal")
    modal.style.animationName = "slideout"
    setTimeout(onRequestClose, 1500)
  } /* istanbul ignore next */

  return (
    <Modal
      id="modal"
      style={{ ...modalStyle }}
      isOpen={isOpen}
      onRequestClose={handleAnimationModal}
      className={`sticky-button-modal wrapper-animation-${wrapperClass}`}
    >
      <section style={titleContainerStyle}>
        {title && (
          <h4 data-testid="titleModal" style={titleStyle}>
            {title}
          </h4>
        )}
        <button style={closeButtonStyle}>
          <CloseButton onClick={handleAnimationModal} />
        </button>
      </section>
      <section>
        <div style={contentContainer}>{children}</div>

        {stickyButton && (
          <div
            data-testid="stickyButtonContainer"
            style={stickyButtonContainer}
          >
            <AssessmentButton
              onClick={handleAnimationModal}
              variant="secondary"
              style={stickyButtonStyle}
            >
              {buttonText}
            </AssessmentButton>
          </div>
        )}
      </section>
    </Modal>
  )
}
