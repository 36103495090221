import React from "react"
import LayoutScreen from "../../components/Layout"
import { BaseScreen } from "../../components/Assessment"
import router from "../../history"
import RequestTimeoutImage from "../../img/408.svg"
import "../../css/ErrorPages.css"

const outerStyle = {
  flex: "1 1 0",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}
export default function RequestTimeout() {
  return (
    <main className="error-page-container">
      <LayoutScreen>
        <div className="error-page-content">
          <BaseScreen
            title={""}
            showButton={true}
            buttonText={"Back to Homepage"}
            onSubmit={() => {
              router.push("/")
            }}
          >
            <div style={outerStyle}>
              <img
                src={RequestTimeoutImage}
                alt="Request Timeout"
                className="error-page-image"
              />
              <h1 className="assessment-title primary">
                {" "}
                Your request took too long.
              </h1>
              <p className="assessment-paragraph">
              Please check your connection or try again.
              </p>
            </div>
          </BaseScreen>
        </div>
      </LayoutScreen>
    </main>
  )
}
