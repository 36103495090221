import { useState, useEffect, useLayoutEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Assessment } from "../components/Assessment"
import SummaryPage from "./Summary"
import OutcomePage from "./Outcome"
import { decodeAssessmentState, encodeScreenState } from "../components/Assessment"
import { createComponent } from "../components/AssessmentComponents"
import LayoutScreen from "../components/Layout"
import AuditContext from "../audit"
import images from "../img/index"
import { client } from "../client"
import ProgressBar from "../components/ProgressBar"

import { usePrompt } from "../prompt"

export function formatAssessmentNumber(n) {
	if (n < 0) {
		return ""
	}
	if (n > 99) {
		return n
	}

	return ("00" + n).slice(-3)
}

export default function AssessmentPage() {
	const { assessmentId } = useParams()
	const [spec, setSpec] = useState({
		children: [{ type: "Screen", id: "loading" }],
		loading: true,
	})
	const [state, setState] = useState({
		state: "assessment",
		isTaking: true,
		index: 0,
		outcome: "",
		loading: true,
	})
	const [doctorAttestation, setDoctorAttestation] = useState("")
	const [assessmentNumber, setAssessmentNumber] = useState(-1)
	// const [searchParams, setSearchParams] = useSearchParams()
	// const returning = searchParams.get("returning")

	const navigate = useNavigate()

	useEffect(() => {
		client
			.getState({ assessmentid: assessmentId })
			.then((response) => {
				const remoteSpec = JSON.parse(response.spec)
				const remoteState = decodeAssessmentState(response.state)
				setSpec(remoteSpec)
				setState(remoteState)
				setAssessmentNumber(response.assessmentnumber)
			})
			.catch((e) => console.log(e))
	}, [assessmentId])

	useLayoutEffect(() => {
		window.scrollTo(0, 0)
	})

	const onEvent = (e) => {
		console.log(e)
		client
			.recordEvent({
				assessmentid: assessmentId,
				eventtype: e.type,
				data: JSON.stringify(e),
			})
			.catch((e) => console.log(e))
	}

	usePrompt("one", state && !state.isKickout && state.outcome !== "ok", onEvent, "assessment")

	const onSubmit = (answers) => {
		const encoded = encodeScreenState(answers)
		client
			.submitScreen({ assessmentid: assessmentId, screenstate: encoded })
			.then((response) => {
				const decoded = decodeAssessmentState(response.state)
				setState(decoded)
			})
			.catch((e) => console.log(e))
	}

	const onEdit = (index) => {
		client
			.editScreen({ assessmentid: assessmentId, index: index })
			.then((response) => {
				const nextState = decodeAssessmentState(response.state)
				setState(nextState)
			})
			.catch((e) => console.log(e))
	}

	const onReturnEdit = (screenId, answers) => {
		return client
			.returningEdit({
				assessmentId,
				screenId,
				answers: JSON.stringify(answers),
			})
			.then((response) => {
				const nextState = decodeAssessmentState(response.state)
				setState(nextState)
			})
	}

	const onConfirm = () => {
		client
			.finishAssessment({ recordid: assessmentId })
			.then((res) => {
				const decoded = decodeAssessmentState(res.state)
				setState(decoded)
			})
			.catch((e) => console.log(e))
	}

	const onBack = () => {
		if (state.isKickout || state.isOutcome || state.state === "returningedit") {
			navigate("/")
			return
		}

		if (state && state.index === 0) {
			navigate("/")
			return
		}

		client
			.goBack({ assessmentid: assessmentId })
			.then((res) => {
				const decoded = decodeAssessmentState(res.state)
				setState(decoded)
			})
			.catch((e) => console.log(e))
	}

	const onDoctorAttestation = (v) => {
		onEvent({
			componentId: "doctorAttestation",
			type: "UPDATED_ANSWER",
			newValue: v,
		})
		setDoctorAttestation(v)
	}

	let assessmentScreen = undefined
	if (state.isTaking || state.isEditing) {
		assessmentScreen = spec.children[state.index]
	}

	let dnuScreen = undefined
	if (state.isKickout) {
		dnuScreen = spec.kickoutScreens.filter((x) => x.id === state.kickoutReason)[0]
	}

	let currentScreen = assessmentScreen || dnuScreen
	let heroSrc = currentScreen && currentScreen.heroImage ? images[currentScreen.heroImage] : undefined

	const canGoBack = state.isTaking || state.isEditing
	const hidingBackButton = currentScreen && !!currentScreen.hideBackButton

	const showBackButton = !hidingBackButton && canGoBack
	const className = state.isTaking || state.isEditing ? "assessment-main" : "assessment-completed"

	const onComponentEvent = (e) => {
		let ev = e
		if (state.isTaking || state.isEditing) {
			const screenId = spec.children[state.index].id
			ev = { ...ev, screenId }

			if (e.type === "MOREINFO_DISPLAYED") {
				const componentId = `${screenId}.moreInfo`
				ev = { ...ev, screenId, componentId }
			}
		}
		onEvent(ev)
	}

	const AssessmentReferenceNumber = () => (
		<div className={`assessment-reference-number ${state.outcome === "ok" ? "outcome-ok" : ""}`}>
			Assessment ID - {`${formatAssessmentNumber(assessmentNumber)}`}
		</div>
	)

	return (
		<AuditContext.Provider value={onComponentEvent}>
			<main className={className} style={{ display: "flex", flexDirection: "column", flex: 1 }}>
				{state.outcome === "" ? (
					<LayoutScreen heroSrc={heroSrc} onBack={onBack} showBackButton={showBackButton}>
						{!spec.loading && !state.loading && (
							<div style={{ width: "100%" }}>
								{(state.isTaking || state.isEditing) && <Assessment spec={spec} state={state} onSubmit={onSubmit} onEvent={onEvent} onBack={onBack} />}
								{state.isReviewing && (
									<SummaryPage spec={spec} answers={state.answers} onEdit={onEdit} onConfirm={onConfirm} client={client} assessmentId={assessmentId} />
								)}
								{state.isKickout && createComponent(spec.kickoutScreens.filter((x) => x.id === state.kickoutReason)[0], { onEvent })}
								{(state.isKickout || state.outcome !== "") && <AssessmentReferenceNumber />}
							</div>
						)}
					</LayoutScreen>
				) : (
					<OutcomePage
						spec={spec}
						state={state}
						doctorAttestation={doctorAttestation}
						onDoctorAttestation={onDoctorAttestation}
						onReturnEdit={onReturnEdit}
						onEvent={onEvent}
						assessmentId={assessmentId}
						referenceNumberComponent={AssessmentReferenceNumber}
					/>
				)}
			</main>
		</AuditContext.Provider>
	)
}
