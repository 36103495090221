import React, { useContext, useState } from 'react'

import ModalContext from '../modal'
import { createComponent,  AssessmentButton } from './AssessmentComponents'

const buttonContainer = {
  width: "100%",
  margin: "0 auto",
  paddingTop: "16px",
}

export function BcCheckbox({
  title,
  value,
  icon,
  variant,
  onCheck,
  checked,
  moreInfo,
  style = {},
}) {
  const checkboxStyle = {
    padding: "0 0 0 0",
    ...style.checkbox,
  }
  return (
    <label
      htmlFor="bc-checkbox"
      className={`checkbox primary`}
      style={checkboxStyle}
    >
      <div className="label-container">
        <div className="checkbox-container" style={{ marginLeft: "0px" }}>
          <input
            data-testid="input-checkbox"
            id="bc-checkbox"
            type="checkbox"
            checked={checked}
            onChange={(e) => onCheck(e.target.checked)}
          />
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
          >
            <path d="M9 1.5H23V0.5H9V1.5ZM30.5 9V23H31.5V9H30.5ZM23 30.5H9V31.5H23V30.5ZM1.5 23V9H0.5V23H1.5ZM9 30.5C4.85786 30.5 1.5 27.1421 1.5 23H0.5C0.5 27.6944 4.30558 31.5 9 31.5V30.5ZM30.5 23C30.5 27.1421 27.1421 30.5 23 30.5V31.5C27.6944 31.5 31.5 27.6944 31.5 23H30.5ZM23 1.5C27.1421 1.5 30.5 4.85786 30.5 9H31.5C31.5 4.30558 27.6944 0.5 23 0.5V1.5ZM9 0.5C4.30558 0.5 0.5 4.30558 0.5 9H1.5C1.5 4.85786 4.85786 1.5 9 1.5V0.5Z" />
            <path d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z" />
            <path
              d="M11 17L14 20L20.5 13"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className="text-container">
          <div style={{ display: "flex" }}>
            <p className="title" style={style.title}>
              {title}
            </p>
          </div>
        </div>
      </div>
    </label>
  )
}

export const BpModal = ({answers: {systolic, diastolic}, onConfirm, onClose}) => (
  <>
    <h2 style={{color: "var(--secondary)"}}>
      It looks like you may have mis-typed your blood pressure
    </h2>

    <div style={{padding: "16px 0"}}>
      <p style={{margin: 0}}>
        You said your BP was <b> {systolic}/{diastolic}</b>. Usually the top number is higher than the bottom number. Is what you have entered correct?
      </p>
    </div>

    <div style={buttonContainer}>
      <AssessmentButton onClick={() => onConfirm()} variant="primary">This is correct</AssessmentButton>
    </div>

    <div style={buttonContainer}>
      <AssessmentButton onClick={onClose} variant="primary outline">Change</AssessmentButton>
    </div>
  </>
)

export const BpNumbersRecentModal = ({onConfirm, onClose, onEvent}) => {

  const onClick = (val) => {
    onEvent({componentId: "bpNumbersRecent", type: "UPDATED_ANSWER", newValue: val})
    onConfirm({bpNumbersRecent: val})
  }

  return (
    <>
      <h2 style={{color: "var(--secondary)"}}>
        Was your blood pressure measured within the last 3 months?
      </h2>

      <div style={{padding: "16px 0"}}>
        <span>
        </span>
      </div>

      <div style={buttonContainer}>
        <AssessmentButton onClick={() => onClick("yes")} variant="primary">Yes</AssessmentButton>
      </div>

      <div style={buttonContainer}>
        <AssessmentButton onClick={() => onClick("no")} variant="primary outline">No</AssessmentButton>
      </div>
    </>
  )
}

function inchesToHeight(value) {
  return `${value/12|0} feet, ${value%12} inches`
}

export const BmiModal = ({answers: {height, weight}, onConfirm, onClose}) => (
  <>
    <h2 style={{color: "var(--secondary)"}}>
      Confirm your information
    </h2>

    <div style={{padding: "16px 0"}}>
      <p style={{margin: 0}}>
        Is your height and weight entered correctly?
      </p>
    </div>

    <div style={{padding: "16px 0"}}>
      <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{display: "flex", flexDirection: "row", padding: "16px 0"}}>
          <div style={{width: 100}}>
            <p style={{margin: 0}}>
              Height:
            </p>
          </div>
          <div>
            <p style={{margin: 0}}>
              <b>{ inchesToHeight(height) }</b>
            </p>
          </div>
        </div>

        <div style={{display: "flex", flexDirection: "row", padding: "16px 0"}}>
          <div style={{width: 100}}>
            <p style={{margin: 0}}>
              Weight:
            </p>
          </div>
          <div>
            <p style={{margin: 0}}>
              <b>{ weight } lbs</b>
            </p>
          </div>
        </div>

      </div>
    </div>

    <div style={buttonContainer}>
      <AssessmentButton onClick={() => onConfirm()} variant="primary">This is correct</AssessmentButton>
    </div>

    <div style={buttonContainer}>
      <AssessmentButton onClick={onClose} variant="primary outline">Change</AssessmentButton>
    </div>
  </>
)

export default function ModalConfirm(props) {
  const [state, setState] = useState({})

  const setModal = useContext(ModalContext)
  const { onSubmit, onEvent, cond, child, ...rest } = props


  const handleSubmit = a => {
    const fn = new Function(`return state => ${cond}`)()

    if (!fn(state)) {
      return onSubmit(a)
    }

    const handleConfirm = () => {
      setModal()
      onSubmit(a)
    }

    const closeModal = () => setModal()

    const createModal = () => (
      <div className='button-container'>
        <input type="button" value="confirm" onClick={handleConfirm}/>
        <input type="button" value="change" onClick={closeModal}/>
      </div>
    )

    setModal(createModal)
  }

  const handleEvent = e => {
    if (e.type == 'UPDATED_ANSWER') {
      setState({...state, [e.componentId]: e.newValue})
    }

    onEvent(e)
  }

  const childProps = {
    ...rest,
    onSubmit: handleSubmit,
    onEvent: handleEvent,
  }

  return createComponent(child, childProps)
}
