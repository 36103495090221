import NavBar from "./NavBar"
import Footer from "./Footer"
import "../css/Layout.css"
import useResponsive from "../responsive"

export default function LayoutScreen({
  heroSrc,
  children,
  displayTextBtn = true,
  onBack,
  showBackButton = false,
  showProfile,
  style = {},
  white = false,
  stickyFooter = false,  // added this new prop
}) {
  const isDesktop = !useResponsive().mobile

  if (white) {
    if (isDesktop) {
      style.layoutMain = {
        background: "white",
        ...style.layoutMain,
      }
    } else {
      style.navbar = {
        header: {
          background: "val(--primary)",
          ...style.navbar?.header,
        },
        ...style.navbar,
      }

      style.root = {
        background: "white",
        ...style.root,
      }
    }
  }

  const navbar = (
    <NavBar
      variant="secondary"
      onBack={onBack}
      isAlt={isDesktop && !!heroSrc}
      showProfile={showProfile}
      showBackButton={showBackButton}
      displayTextBtn={displayTextBtn}
      style={style.navbar}
    />
  )

  const footerStyling = stickyFooter ? { position: 'sticky', bottom: '0' } : {}
  const footer = <Footer style={footerStyling} />

  if (isDesktop) {
    return (
      <DesktopScreen
        hero={heroSrc}
        content={children}
        navbar={navbar}
        style={style}
        footer={footer}
      />
    )
  }
  return (
    <MobileScreen
      hero={heroSrc}
      content={children}
      navbar={navbar}
      style={style}
      footer={footer}
    />
  )
}

function DesktopScreen({ hero, content, navbar, progress, footer, style = {} }) {
  return (
    <div
      className={`layout-desktop ${hero ? "has-hero" : "no-hero"}`}
      style={style.root}
    >
      {navbar}
      <div className="layout-main" style={style.layoutMain}>
        <div
          style={{
            flex: 1,
          }}
        >
          <div className="layout-content">{content}</div>
        </div>
      </div>
      {footer}
    </div>
  )
}

function MobileScreen({ hero, content, navbar, footer, style = {} }) {
  return (
    <div
      className={`layout-mobile ${hero ? "has-hero" : "no-hero"}`}
      style={style.root}
    >
      {navbar}
      <div className="layout-main" style={style.layoutMain}>
        <div
          style={{
            width: "clamp(320px, 100%, 500px)",
            ...style.layoutMainMobileInner,
          }}
        >
          {content}
        </div>
      </div>
      {footer}
    </div>
  )
}
