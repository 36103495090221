import { DefaultAssessmentService, DefaultCheckoutService } from "./service"
import { jwtDecode } from "jwt-decode"
import store from "../store"

function Mutex() {
  let locked = false
  let waiting = []

  function lock() {
    if (!locked) {
      locked = true
      return
    }

    let unlocked
    const p = new Promise((r) => (unlocked = r))
    waiting.push(unlocked)

    return p
  }

  function unlock() {
    if (!waiting.length) {
      locked = false
      return
    }

    const next = waiting.pop()
    next(1)
  }

  return {
    lock,
    unlock,
  }
}

function getSearchParams() {
  const ret = {}
  for (const [k, v] of window.location.search
    .slice(1)
    .split("&")
    .map((x) => x.split("="))) {
    ret[k] = v
  }
  return ret
}

function decodeToken(token) {
  try {
    const decoded = jwtDecode(token)
    console.log(decoded)
    console.log(decoded.email)
    sessionStorage.setItem("email", decoded.email)
  } catch (e) {
    console.log("error decoding token", e)
    return null
  }
}

function AuthClient(tok) {
  const mu = Mutex()
  let _token = tok

  // actually makes the API call to get the token
  async function _getToken() {
    const { invite, clinician } = getSearchParams()

    try {
      const res = await fetch("/auth/token", {
        method: "POST",
        body: JSON.stringify({
          invite,
          clinician,
        }),
      })

      if (res.status !== 200) {
        // Handle different error statuses specifically
        const errorMessage = `Error getting token: Received status code ${res.status}`
        console.error(errorMessage)
        throw new Error(errorMessage) // Propagate the error
      }

      const token = await res.json()
      return token
    } catch (error) {
      throw error // Re-throw the error to be handled by the caller
    }
  }

  // removes token from session storage and the one in memory
  function clearToken() {
    sessionStorage.removeItem("token")
    _token = null
  }

  // decodes jwt token and sets it in session storage
  function setToken(token) {
    if (!token) {
      console.error("Cannot set token: Token is null or undefined")
      return
    }

    sessionStorage.setItem("token", JSON.stringify(token))
    decodeToken(JSON.stringify(token))
    _token = token
  }

  // checks if token is in session storage, if not, gets it from the API
  async function getToken() {
    try {
      await mu.lock()
      if (!_token) {
        const t = await _getToken()
        if (t) {
          setToken(t)
        } else {
          throw new Error("Token is null or undefined after _getToken call")
        }
      } else {
        // check if token is expired, if so, get a new one. This can also just be done with better error handling on the backend
        let expiry = sessionStorage.getItem("token").expiry // this is assuming i dont need to decode the token to get the expiry
        if (expiry < Date.now()) {
          clearToken()
          const t = await _getToken()
          if (t) {
            setToken(t)
          } else {
            throw new Error("Token is null or undefined after _getToken call")
          }
        }
      }
    } catch (error) {
      console.error("Failed to get or set token:", error)
      throw error // rethrow to let caller handle it
    } finally {
      mu.unlock()
    }

    return _token ? _token.token : null // Return null if _token is falsy
  }

  // makes an api call with token included in header
  async function fetchWithToken(req) {
    const token = await getToken()
    if (token) {
      req.headers.append("authorization", token)
    }
    return fetch(req)
  }

  async function guestLogin() {
    const token = await _getToken()
    setToken(token)
    sessionStorage.removeItem("authed")

    store.dispatch({ type: "GUEST_LOGIN_SUCCESS", token })
  }

  async function appleLogin(authorization, mode, transfer) {
    const tok = await getToken()

    const opts = {
      method: "POST",
      headers: {
        authorization: tok,
      },
      body: JSON.stringify({
        mode,
        transfer,
        origin: window.location.origin,
        ...authorization,
      }),
    }

    const res = await fetch("/auth/apple/success", opts)
    const { status, token, history } = await res.json()
    setToken(token)

    return { status, token, history }
  }

  // native account creation
  async function nativeCreateAccount(email, password) {
    let tknObj = JSON.parse(sessionStorage.getItem("token"))
    console.log("token object", tknObj)
    const opts = {
      method: "POST",
      headers: {
        authorization: tknObj.token,
      },
      body: JSON.stringify({
        email,
        password,
        origin: window.location.origin,
      }),
    }
    try {
      const res = await fetch("/auth/native-signup", opts)

      if (!res.ok) {
        try {
          const errorResponse = await res.json()
          console.log("Error response:", errorResponse.message)
          throw new Error(`${errorResponse.message}`)
        } catch (err) {
          throw new Error(`Invite token has been claimed`)
        }
      }

      const { token } = await res.json()
      setToken(token)

      sessionStorage.setItem("OutcomeFlow_showSignInScreen", false)

      return { status: "ok", token }
    } catch (err) {
      console.error("Error during native sign-in:", err)
      throw err
    }
  }

  async function nativeSignIn(email, password) {
    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, origin: window.location.origin }),
    }

    try {
      const res = await fetch("/auth/native-login", opts)

      if (!res.ok) {
        const errorResponse = await res.json()
        console.log("Error response:", errorResponse.message)
        throw new Error(`${errorResponse.message}`)
      }

      const { token } = await res.json()
      setToken(token)

      sessionStorage.setItem("OutcomeFlow_showSignInScreen", false)

      return { status: "ok", token } // Return the relevant information
    } catch (err) {
      console.error("Error during native sign-in:", err)
      throw err // Re-throw the error to be handled by the caller
    }
  }

  async function googleLogin(code, mode, transfer) {
    const tok = await getToken()

    const opts = {
      method: "POST",
      headers: {
        authorization: tok,
      },
      body: JSON.stringify({
        origin: window.location.origin,
        code,
        mode,
        transfer,
      }),
    }

    const res = await fetch("/auth/google/oauth", opts)
    const { status, token, history } = await res.json()
    setToken(token)

    return { status, token, history }
  }

  async function getHistory() {
    const req = new Request("/auth/history", { method: "GET" })
    const res = await fetchWithToken(req)
    const { history } = await res.json()
    return history
  }

  return {
    nativeSignIn,
    nativeCreateAccount,
    getToken,
    clearToken,
    setToken,
    getHistory,
    fetchWithToken,
    appleLogin,
    googleLogin,
    guestLogin,
  }
}

const existingToken = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))
export const authClient = AuthClient(existingToken)
export const client = new DefaultAssessmentService("", authClient.fetchWithToken)
export const checkoutClient = new DefaultCheckoutService("", authClient.fetchWithToken)

//export const authClient = new DefaultAuthService('', fetchWithAuth)
