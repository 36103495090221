import { RadioList } from '../../components/AssessmentComponents'

// CSS
import "../../css/ShippingInformation.css"


export default function ShippingMethod({value, onSelect}) {

  const options = [
    {
      value: "3to5",
      title: "Ground 3-5 business days",
      rightText: `$${(599 / 100).toFixed(2)}`, // Convert to dollars and format as a string
      cost: 599,
    },
    {
      value: "1to2",
      title: "Expedited 1-2 business days",
      rightText: `$${(1099 / 100).toFixed(2)}`, // Convert to dollars and format as a string
      cost: 1099,
    }
  ]

  const handleSelect = (selectedValue) => {
    const selectedOption = options.find(option => option.value === selectedValue)
    onSelect(selectedOption)
  }
  
  return (
    <RadioList id="select-shipping-method" options={options} value={value.value} onSelect={handleSelect}/>
    )
}