import { useState, useEffect, createElement } from 'react'
import { APIAssessment } from '../components/Assessment'
import bg from '../img/bg.png'
import LayoutScreen from '../components/Layout'
import NavBar from '../components/NavBar'
import images from '../img'
import ProductSelect, { OrderSummary } from '../components/Product'
import SelectProduct from '../pages/SelectProduct.js'
import {RadioSelectList} from '../components/AssessmentComponents.js'
import Radio, {RadioGroup} from '../components/Radio'
import { ReactComponent as Exclamation } from '../icn/circle-exclamation.svg'

import {
  Assessment
} from '../components/Assessment'
import CheckoutPage from './CheckoutTmp.js'

import history from '../history'
import PrivacyRest from '../components/PrivacyRest'

const spec = {
  type: 'Assessment',
  id: 'A297BAC6-8B43-4A55-8F38-8781BC2E2A52',
  children: [
    {
      type: 'Screen',
      id: '8D4D5B2D-0C45-4F99-8F5D-C658AE7910D5',
      title: 'first screen',
      showContinueButton: true,
      children: [
        {
          type: 'MultiChoiceButtons',
          id: '0100AC68-A026-425E-A77E-39D7F45E7C77',
          question: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit',
          summary: 'A or B'
        },
        {
          type: 'TextfieldQuestion',
          id: 'C42E4A2A-5852-49D3-9284-BFA4C6DF7207',
          question: 'what is your first Name',
          summary: 'first Name'
        },
        {
          type: 'TextfieldQuestion',
          id: '1D610317-7B93-4C9A-A883-556356E3764C',
          question: 'what is your last Name',
          summary: 'last name'
        },
        {
          type: 'YesNoRadioQuestion',
          id: 'E69B46B8-2BBB-4CD8-90EA-BB76D673EE58',
          question: 'Lorem ipsum dolor sit amet?',
          summary: 'lorem x'
        },
        {
          type: 'YesNoRadioQuestion',
          id: '1D38BC88-F44A-49DD-AC8E-6BF11CD4D60C',
          question: 'Lorem ipsum dolor sit amet?',
          summary: 'lorem y'
        },
        {
          type: 'YesNoRadioQuestion',
          id: '416FFE2A-5EAF-4119-A5BA-817E4698B286',
          question: 'Lorem ipsum dolor sit amet?',
          summary: 'lorem z'
        }
      ]
    },
    {
      type: 'Screen',
      id: 'EAD84845-3D6D-46CC-9F26-11466F3657BB',
      title: 'second screen',
      showContinueButton: true,
      children: [
        {
          type: 'Paragraph',
          id: 'FF02EB9A-78F4-416F-B587-378E452BB149',
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
      ]
    }
  ]
}



export default function Experimental({ client }) {
                                                   const [
                                                     cur,
                                                     setCur,
                                                   ] = useState(0)

                                                   const [f, setf] = useState(
                                                     undefined
                                                   )
                                                   const [
                                                     showA,
                                                     setShowA,
                                                   ] = useState(false)

                                                   return (
                                                     <div
                                                       style={{
                                                         background: "black",
                                                         padding: "16px",
                                                       }}
                                                     >
                                                       <Exclamation />
                                                     </div>
                                                   )
                                                 }

function RegisterA({ register }) {
  const [state, _] = useState({ a: "somestate" })
  const f = () => console.log("a state", state)
  useEffect(() => {
    register(f)
  }, [])
  return "A"
}

function RegisterB({ register }) {
  const f = () => console.log("b")
  useEffect(() => {
    register(f)
  }, [])
  return "B"
}

function Stepper({
  steps = [],
  shownav = true,
  onBack = (goBack) => {
    goBack()
  },
  onNext = (goNext) => {
    goNext()
  },
  buttonText = { back: "back", next: "next" },
}) {
  const [i, seti] = useState(0)

  // useEffect(() => {init(seti)}, [])

  if (steps.length === 0) {
    return null
  }

  const goBack = () => seti((i) => Math.max(0, i - 1))
  const goNext = () => seti((i) => Math.min(steps.length - 1, i + 1))

  return (
    <>
      {createElement(steps[i], {}, [])}
      {shownav && (
        <div>
          <button
            className="btn primary outline"
            onClick={(_) => onBack(goBack)}
          >
            {buttonText.back}
          </button>
          <button className="btn primary" onClick={(_) => onNext(goNext)}>
            {buttonText.next}
          </button>
        </div>
      )}
    </>
  )
}

function PrivacyScreen() {
  useEffect(() => {
    console.log("privacy screen loaded")
  }, [])

  return <div>privacy screen</div>
}

function ConfirmNumbersScreen() {
  useEffect(() => {
    console.log("confirm numbers screen loaded")
  }, [])
  return <div>do you know your numbers screen</div>
}

function Progress({ cur, steps }) {
  const children = steps.map((s, i) => {
    let state = ""
    if (i < cur) {
      state = "completed"
    }
    if (i === cur) {
      state = "current"
    }
    if (i > cur) {
      state = "incomplete"
    }
    return <Step key={s} title={s} state={state} />
  })

  const numSpaces = steps.length - 1

  const progress = Math.min(((cur / numSpaces) * 100).toFixed(2), 100)

  return (
    <div className="progress">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {children}
        </div>
        <div style={{ height: "2px", background: "lightgrey" }}>
          <div
            className="inner-bar"
            style={{ height: "100%", width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  )
}

function Step({ title, state }) {
  return (
    <div className={`progress-step ${state}`}>
      <span>{title}</span>
    </div>
  )
}

function Component({ object }) {
  console.log("hello")
  return <div>name: {object.name}</div>
}

function Carousel({
  children = [],
  cur = 0,
  back = () => {},
  next = () => {},
}) {
  return (
    <div>
      {children.map((c, i) => (
        <div style={{ display: i === cur ? undefined : "none" }}>{c}</div>
      ))}
      <button onClick={back}>back</button>
      <button onClick={next}>next</button>
    </div>
  )
}

function withShow(Wrapped) {
  return ({ show = false, ...rest }) => {
    return (
      <div style={{ display: show ? undefined : "none" }}>
        <Wrapped {...rest} />
      </div>
    )
  }
}

function A({ a }) {
  return <div>{a}</div>
}

function B({ b }) {
  return <div>{b}</div>
}

function C({ c }) {
  return <div>{c}</div>
}
