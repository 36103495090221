import welcome from './welcome.png'
import success from "./success.png"
import bloodPressure from './blood-pressure.png'
import stop from './stop.png'
import adbu from './adbu.png'
import selectPackImg from "./select-pack-img.png"
import sampleQR from "./sample-qr.png"
import visa from "./visa.svg"
import amex from "./amex.svg"
import mastercard from "./mastercard.svg"
import discover from "./card-discover.png"
import nitrate1 from "./nitrate1.png"
import nitrate2 from "./nitrate2.png"
import nitrate3 from "./nitrate3.png"

const images = {
  welcome: welcome,
  success: success,
  "blood-pressure": bloodPressure,
  adbu: adbu,
  stop: stop,
  "select-pack-img": selectPackImg,
  "sample-qr": sampleQR,
  "card-visa": visa,
  "card-amex": amex,
  "card-mastercard": mastercard,
  "card-discover": discover,
  "nitrate1": nitrate1,
  "nitrate2": nitrate2,
  "nitrate3": nitrate3,
}

export default images
