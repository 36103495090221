import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { googleAuth, appleAuth, Auth } from "./Auth"
import Textfield, { WithLabel, WithNote, RequiredField } from "./Textfield"
import "../css/SignUp.css"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { style, TermsLink, SignInLink, isPasswordValid, getRuleDescription, isEmailValid, isConfirmEmailValid, isConfirmPasswordValid } from "./signUpAssets"
import { authClient, client } from "../client"
import { assessmentStarted } from "../reducers"
import icons from "../icn/index"

import useResponsive from "../responsive"
import { AssessmentButton } from "./AssessmentComponents"
import { AuthWrapper } from "./AuthWrapper"

export function SignUpComponent({ onSubmit, children }) {
  const authed = useSelector((state) => state.auth.authed)
  const [searchParams, setSearchParams] = useSearchParams()
  const { desktop, mobile } = useResponsive()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const assessmentId = searchParams.get("assessmentId")
  const assessmentState = searchParams.get("assessmentState")

  // State object to manage the form fields and their validation
  const [state, setState] = useState({
    email: "",
    emailValid: true,
    emailTouched: false,
    emailTooLong: false,
    confirmEmail: "",
    confirmEmailValid: true,
    confirmEmailTouched: false,
    confirmEmailTooLong: false,
    password: "",
    passwordValid: true,
    passwordTouched: false,
    confirmPassword: "",
    confirmPasswordValid: true,
    confirmPasswordTouched: false,
  })
  const [error, setError] = useState(null)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [passwordState, setPasswordState] = useState({
    password: "",
    passwordValid: false,
    passwordTouched: false,
    showTooltip: false,
    validations: {
      minimum: false,
      maximum: false,
      number: false,
      uppercase: false,
      lowercase: false,
      commonWords: false,
    },
  })
  
  // Retrieve the email from sessionStorage when the component mounts
  useEffect(() => {
    document.body.classList.add('signup-page-background')
    return () => {
      document.body.classList.remove('signup-page-background')
    }
    const storedEmail = sessionStorage.getItem("email")
    if (storedEmail) {
      setState((prevState) => ({
        ...prevState,
        email: storedEmail,
        emailTouched: true, // Mark email as touched to avoid validation checks
        emailValid: true, // Assume stored email is valid
      }))
    }
  }, [])

  // Handle field changes and update the state
const handleFieldChange = (field, value) => {
  let newState = {
    ...state,
    [field]: value,
  }

  // Validate email fields
  if (field === "email" || field === "confirmEmail") {
    newState.emailValid = isEmailValid(newState.email)
    newState.confirmEmailValid = isConfirmEmailValid(newState.email, newState.confirmEmail)
    newState.confirmEmailTouched = true // Set touched to true for immediate feedback
  }

  // Validate email field length
  if (field === "email") {
    newState.emailTooLong = value.length > 60
    newState.emailValid = isEmailValid(value) && !newState.emailTooLong
  }

  // Validate confirm email field length
  if (field === "confirmEmail") {
    newState.confirmEmailTooLong = value.length > 60
    newState.confirmEmailValid = isConfirmEmailValid(state.email, value) && !newState.confirmEmailTooLong
  }

  // Validate password fields
  if (field === "password") {
    const validationResults = isPasswordValid(value)
    const rulesMetCount = Object.values(validationResults).filter(Boolean).length
    setPasswordState({
      ...passwordState,
      password: value,
      passwordValid: validationResults.valid,
      validations: validationResults,
      passwordTouched: true,
      rulesMetCount,
      showTooltip: !validationResults.valid,
    })
    // Re-validate confirm password whenever the password changes
    newState.confirmPasswordValid = isConfirmPasswordValid(value, state.confirmPassword)
  }

  // Validate confirmPassword field
  if (field === "confirmPassword") {
    newState.confirmPasswordValid = isConfirmPasswordValid(state.password, value)
    newState.confirmPasswordTouched = true // Set touched to true for immediate feedback
  }

  // Update the state with the new values
  setState(newState)
}

  // Handle password visibility toggle
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  // Handle confirm password visibility toggle
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  }

  // Handle confirmPassword field focus event
  const handleConfirmPasswordFocus = () => {
    if (!state.confirmPasswordTouched) {
      // Mark the confirmPassword field as touched when it receives focus
      setState((prevState) => ({
        ...prevState,
        confirmPasswordTouched: true,
      }))
    }
  }

  // Handle field blur events
  const handleFieldBlur = (field) => {
    if (field === "email") {
      // Perform email validation
      const isValid = isEmailValid(state.email)
      setState((prevState) => ({
        ...prevState,
        emailValid: isValid,
        emailTouched: true,
      }))
    } else if (field === "password") {
      // Perform password validation on blur
      const isValid = isPasswordValid(state.password)
      setState((prevState) => ({
        ...prevState,
        passwordValid: isValid,
        passwordTouched: true,
      }))
    } else   if (field === "password") {
      setPasswordState({ ...passwordState, showTooltip: false })
    }
    else if (field === "confirmEmail") {
      // Handle confirm email field blur
      const isValid = isConfirmEmailValid(state.email, state.confirmEmail)
      setState(prevState => ({
        ...prevState,
        confirmEmailValid: isValid,
        confirmEmailTouched: true,
      }))
    }
  }

  // Handle focus event on password field
  const handlePasswordFocus = () => {
    setPasswordState({ ...passwordState, showTooltip: true })
  }

  // Check if all form fields are valid
  const isFormValid = () => {
    return (
      state.emailValid &&
      !state.emailTooLong &&
      state.confirmEmailValid &&
      !state.confirmEmailTooLong &&
      passwordState.passwordValid &&
      state.confirmPasswordValid
    )
  }

  // Handle form submission
  const handleSubmit = async () => {
    if (isFormValid()) {
      //  ============================================ SUBMIT FORM ============================================
      try {
        const signInResult = await authClient.nativeCreateAccount(state.email, state.password)
        // If sign in is successful, proceed with dispatch
        if (signInResult.status === "ok") {
          if (!assessmentId) {
            dispatch(assessmentStarted())
            return
          }

          if (assessmentState == "assessment") {
            await client.submitScreen({assessmentid: assessmentId, screenstate: {answers: "{}"}})
          } else if (assessmentState == "outcome") {
            sessionStorage.setItem("OutcomeFlow_showSignInScreen", "false")
          }

          navigate(`/assessment/${assessmentId}`)
        }
      } catch (err) {
        // Handle any errors that occur during the sign-in process
        console.error("Error during native sign-in:", err)
        setError(`Invite token has been claimed`)
        return
      }
    } else {
      console.log("INVALID FORM")
      // Handle form submission errors or display validation messages
    }
  }

  // Authentication handlers
  const onApple = async (authType) => {
    await appleAuth(authType, true)
    onSubmit()
  }

  const onGoogle = async (authType) => {
    await googleAuth(authType, true)
    onSubmit()
  }

  const onGuest = async () => {
    if (!assessmentId) {
      dispatch(assessmentStarted())
      return
    }

    if (assessmentState == "assessment") {
      await client.submitScreen({assessmentid: assessmentId, screenstate: {answers: "{}"}})
    } else if (assessmentState == "outcome") {
      sessionStorage.setItem("OutcomeFlow_showSignInScreen", "false")
    }

    navigate(`/assessment/${assessmentId}`)
  }

  const desktopStyle = {
    margin: '24px 64px 24px 64px',
  }

  const Rectangle = ({ isFilled }) => {
    const rectangleStyle = {
      height: '6px',
      flex: 1, // Takes up available space
      background: isFilled ? 'var(--success)' : '#9CA1B0',
      marginLeft: '2px', // Margin to the left for spacing
      marginRight: '2px', // Margin to the right for spacing
    }
  
    return <div style={rectangleStyle}></div>
  }
  
  return (
    <div
      style={{
        display: "flex",
        flexDirection: mobile ? "column" : desktop ? "row" : "column",
        backgroundColor: "#F9F9F9",
        ...desktop && desktopStyle,
      }}
    >
      {/* Left Side - User Input Fields */}
      <div className="sign-in" style={{ ...style.leftContainer, flex: desktop ? 1 : "unset" }}>
        <p className="required-text">*Field is required</p>
        <RequiredField label="Email Address" id="email">
        <Textfield
          type="text"
          id="email"
          data-testid="email-input"
          placeholder="name@example.com"
          onChange={(e) => handleFieldChange("email", e.target.value)}
          onBlur={() => handleFieldBlur("email")}
          className={`signup-email-input ${state.emailTooLong || (state.emailTouched && !state.emailValid) ? "input-error" : ""}`}
        />
          {state.emailTouched && !state.emailValid ? (
            <div className="error">Invalid Email Address. Please enter a valid email in this format: example@gmail.com.</div>
          ) : null}
          {state.emailTooLong ? (
            <div className="error">The email field may not be greater than 60 characters</div>
          ) : null}
        </RequiredField>
        <RequiredField label="Confirm Email" id="confirm-email">
        <Textfield
          type="text"
          id="confirm-email"
          data-testid="confirm-email-input"
          placeholder="name@example.com"
          onChange={(e) => handleFieldChange("confirmEmail", e.target.value)}
          onBlur={() => handleFieldBlur("confirmEmail")}
          className={`signup-email-input ${
            state.confirmEmailTooLong || (state.confirmEmailTouched && !state.confirmEmailValid && state.confirmEmail.length > 0) ? "input-error" : ""
          }`}
        />
          {state.confirmEmailTouched && !state.confirmEmailValid && !state.confirmEmailTooLong && state.confirmEmail.length > 0 && (
            <div className="error">Email and Confirm Email do not match. Please enter again.</div>
          )}
          {state.confirmEmailTooLong ? (
            <div className="error">The email field may not be greater than 60 characters</div>
          ) : null}
        </RequiredField>
        <RequiredField label="Password" id="password">
          <div className="password-field-container">
            {passwordState.passwordTouched && !passwordState.passwordValid && (
              <span className="weak-indicator">Weak</span>
            )}
            <Textfield
              type={passwordVisible ? "text" : "password"}
              id="password"
              data-testid="password-input"
              placeholder="password"
              value={passwordState.password}
              onChange={(e) => handleFieldChange("password", e.target.value)}
              onFocus={handlePasswordFocus}
              onBlur={() => handleFieldBlur("password")}
              className={`signup-password-input ${
                passwordState.passwordTouched ? (passwordState.passwordValid ? "input-success" : "input-error") : ""
              }`}
            />
            {passwordState.passwordValid && passwordState.passwordTouched && (
              <img src={icons["green-check"]} className="icon-inside-input" alt="Valid" />
            )}
            <button onClick={togglePasswordVisibility} className="password-toggle-button">
              <img src={passwordVisible ? icons["eye"] : icons["eye-slash"]} alt="Toggle visibility" />
            </button>
          </div>
          {passwordState.showTooltip && (
          <div className="password-tooltip" data-testid='password-tooltip'>
            <div className="password-tooltip-title">Your password must:</div>
            <ul>
              {Object.entries(passwordState.validations)
                .filter(([rule, _]) => rule !== 'valid') // Exclude 'valid' key
                .map(([rule, isValid]) => (
                  <li key={rule} className={isValid ? 'valid' : ''}>
                    {isValid ? <img src={icons["green-check"]} alt="Valid" /> : <img src={icons["red-x"]} alt="Invalid" />}
                    <span>{getRuleDescription(rule)}</span>
                  </li>
                ))}
            </ul>
          </div>
          )}
        </RequiredField>
        {/* Password strength rectangles */}
        {passwordState.passwordTouched && !passwordState.passwordValid && (
          <div className="password-strength-indicator">
            {[...Array(6)].map((_, i) => (
              <Rectangle key={i} isFilled={i < passwordState.rulesMetCount} />
            ))}
          </div>
        )}
        <RequiredField label="Confirm Password" id="confirm-password">
          <div className="password-field-container">
            <Textfield
              type={confirmPasswordVisible ? "text" : "password"}
              id="confirm-password"
              data-testid="confirm-password-input"
              placeholder="password"
              value={state.confirmPassword}
              onChange={(e) => handleFieldChange("confirmPassword", e.target.value)}
              onBlur={() => handleFieldBlur("confirmPassword")}
              className={`signup-confirm-password-input ${
                state.confirmPasswordTouched ? (state.confirmPasswordValid ? "input-success" : "input-error") : ""
              }`}
            />
            {state.confirmPasswordValid && state.confirmPasswordTouched && state.confirmPassword.length > 0 && (
              <img src={icons["green-check"]} className="icon-inside-input" alt="Valid" />
            )}
            <button onClick={toggleConfirmPasswordVisibility} className="password-toggle-button">
              <img src={confirmPasswordVisible ? icons["eye"] : icons["eye-slash"]} alt="Toggle visibility" />
            </button>
          </div>
          {state.confirmPasswordTouched && !state.confirmPasswordValid && state.confirmPassword.length > 0 && (
            <div className="error">Password and Confirm Password do not match. Please enter again.</div>
          )}
        </RequiredField>
        <AssessmentButton data-testid="submit-button" style={{ marginTop: "24px" }} variant="secondary" onClick={handleSubmit} disabled={!isFormValid()}>
          Create Account & Sign In
        </AssessmentButton>
        {error ? <div className="sign-in-error">{error}</div> : null}

      </div>

      {/* Divider */}
      <div
        style={{
          display: "flex",
          flexDirection: mobile ? "row" : "column",
          alignItems: "center", // Ensures vertical centering in desktop view
          justifyContent: "center",
          margin: "20px 0",
        }}
      >
        {/* Divider to the Left - Applicable for both Desktop and Mobile */}
        <div
          style={{
            height: mobile ? "2px" : "130px",
            width: mobile ? "40%" : "2px",
            backgroundColor: "#ccc",
            alignSelf: mobile ? "center" : "stretch", // Stretch for desktop to fill container height
            margin: mobile ? "0 10px 0 0" : "0 0 0 16px",
          }}
        ></div>

        {/* Text */}
        <p
          style={{
            margin: "16px 0 16px 0",
            fontSize: "1rem",
            fontWeight: "600",
            alignSelf: "center",
            padding: "0 10px",
          }}
        >
          Or
        </p>

        {/* Divider to the Right - Applicable for both Desktop and Mobile */}
        <div
          style={{
            height: mobile ? "2px" : "130px",
            width: mobile ? "40%" : "2px",
            backgroundColor: "#ccc",
            alignSelf: mobile ? "center" : "stretch", // Stretch for desktop to fill container height
            margin: mobile ? "0 0 0 10px" : "0 0 0 16px",
          }}
        ></div>
      </div>

      {/* Right Side - oAuth */}
      <div style={{ ...style.rightContainer, flex: desktop ? 1 : "unset", display: mobile ? "block" : "flex" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "392px",
              margin: "0 28px 32px 28px",
            }}
          >
            <div
              style={{
                marginTop: "1.5rem",
                textAlign: "center",
              }}
            >
              <SignInLink searchParams={searchParams}>Have an account? Sign in</SignInLink>
            </div>
            <AuthWrapper
              onGoogle={onGoogle}
              onApple={onApple}
              onGuest={onGuest}
              desktop={desktop}
              displayTitle={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
