import { Auth } from "./Auth"
import useResponsive from "../responsive"

import "../css/AuthWrapper.css"


export const AuthWrapper = ({ onGoogle, onApple, onGuest, displayTitle=true, assessmentState }) => {
  const { mobile } = useResponsive()

  return (
    <main className="auth-wrapper-container">
      { displayTitle && (
        <h1 className="auth-title">
          Don't lose your progress. { mobile && (<br />)}Create an account.
        </h1>
      )}

      <div className="auth-container">
        <Auth
          assessmentState={assessmentState}
          onSelect={(name, _) => {
            if (name === "guest") {
              onGuest()
            } else if (name === "google") {
              onGoogle()
            } else if (name === "apple") {
              onApple()
            }
          }}
        />
        <p className="text-policy">
          By using your Google or Apple ID you accept our <span className="text-link">terms of use</span> and <span className="text-link">privacy policy</span>. 
          { mobile && (<br />)} If you continue as a guest, your answers will not be saved when you quit and you will need to restart.
        </p>
      </div>
    </main>
  )
}
