import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { googleAuth, appleAuth, Auth } from "./Auth"
import Textfield, { WithLabel, RequiredField } from "./Textfield"
import "../css/SignUp.css"
import { authClient, client } from "../client"
import { assessmentStarted } from "../reducers"
import useResponsive from "../responsive"
import { useDispatch } from "react-redux"
import { useSearchParams, useNavigate } from "react-router-dom"
import icons from "../icn/index"

import { style, TermsLink, SignUpLink, ForgotPasswordLink, isEmailValid, isPasswordValid } from "./signUpAssets"
import { AssessmentButton } from "./AssessmentComponents"
import { AuthWrapper } from "./AuthWrapper"

import "../css/SignUp.css"

export function SignInComponent({ onSubmit, children }) {
  const authed = useSelector((state) => state.auth.authed)
  const [showSignIn, setShowSignIn] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)
  const { desktop, mobile } = useResponsive()
  const [searchParams, setSearchParams] = useSearchParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const assessmentId = searchParams.get("assessmentId")
  const assessmentState = searchParams.get("assessmentState")

  // State object to manage the form fields and their validation
  const [state, setState] = useState({
    email: "",
    emailValid: false,
    emailTouched: false,
    password: "",
    passwordValid: false,
    passwordTouched: false,
  });
  

  const [error, setError] = useState(null)
  const [passwordVisible, setPasswordVisible] = useState(false)

  // Retrieve the email from sessionStorage when the component mounts
  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email")
    if (storedEmail) {
      setState((prevState) => ({
        ...prevState,
        email: storedEmail,
      }))
    }
  }, [])

  // Handle field changes and update the state
  const handleFieldChange = (field, value) => {
    if (field === "email") {
      const emailValid = isEmailValid(value)
      setState((prevState) => ({
        ...prevState,
        email: value,
        emailValid,
        emailTouched: true,
      }))
      setError(null)
    } else if (field === "password") {
      const passwordValid = value.length >= 8
      setState((prevState) => ({
        ...prevState,
        password: value,
        passwordValid,
        passwordTouched: true, // Update to true as user has touched the field
      }))
      setError(null)
    }
    setSubmissionError(false); // Reset submission error on any input change
  }

  // Handle password visibility toggle
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  // Handle field blur events
  const handleFieldBlur = (field) => {
    if (field === "email") {
      // Handle email field blur
      const isValid = isEmailValid(state.email)
      setState((prevState) => ({
        ...prevState,
        emailValid: isValid,
        emailTouched: true,
      }))
    } else if (field === "password") {
      const isValid = state.password.length >= 8
      setState((prevState) => ({
        ...prevState,
        passwordValid: isValid,
        passwordTouched: true,
      }))
    }
  }

  // Check if all form fields are valid
  // Do we need field validation for sign in?
  const isFormValid = () => {
    return state.emailValid && state.passwordValid && !submissionError
  }
  
  // Handle form submission
  const handleSubmit = async () => {
    if (isFormValid() && !submissionError) {
      //  ============================================ SUBMIT FORM ============================================
      try {
        const signInResult = await authClient.nativeSignIn(state.email, state.password)
        // If sign in is successful, proceed with dispatch
        if (signInResult.status === "ok") {

          if (!assessmentId) {
            dispatch(assessmentStarted())
            return
          }

          if (assessmentState == "assessment") {
            await client.submitScreen({assessmentid: assessmentId, screenstate: {answers: "{}"}})
          } else if (assessmentState == "outcome") {
            sessionStorage.setItem("OutcomeFlow_showSignInScreen", "false")
          }

          navigate(`/assessment/${assessmentId}`)
        }
      } catch (err) {
        // Handle any errors that occur during the sign-in process
        console.error("Error during native sign-in:", err)
        setError(err.message)
        // Set submission error flag
        setSubmissionError(true);
        return
      }
    } else {
      console.log("INVALID FORM")
      // Handle form submission errors or display validation messages
    }
  }

  // Authentication handlers
  const onApple = async (authType) => {
    await appleAuth(authType, true)
    onSubmit()
  }

  const onGoogle = async (authType) => {
    await googleAuth(authType, true)
    onSubmit()
  }

  const onGuest = async () => {
    if (!assessmentId) {
      dispatch(assessmentStarted())
      return
    }

    if (assessmentState == "assessment") {
      await client.submitScreen({assessmentid: assessmentId, screenstate: {answers: "{}"}})
    } else if (assessmentState == "outcome") {
      sessionStorage.setItem("OutcomeFlow_showSignInScreen", "false")
    }

    navigate(`/assessment/${assessmentId}`)
  }

  useEffect(() => {
    // Add or remove class to the body tag based on the component type
    const bodyClass = "sign-in-page"
    document.body.classList.add(bodyClass)
    return () => {
      document.body.classList.remove(bodyClass)
    }
  }, [showSignIn])

  const desktopStyle = {
    margin: '24px 64px 24px 64px',
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: mobile ? "column" : desktop ? "row" : "column",
          ...desktop && desktopStyle,
        }}
      >
        {/* Left Side - User Input Fields */}
        <div className="sign-in" style={{ ...style.leftContainer, flex: desktop ? 1 : "unset" }}>
          <WithLabel label="Email Address">
            <Textfield
              type="text"
              placeholder="name@example.com"
              style={style.inputField}
              onChange={(e) => {
                // Clear the error message as soon as the user starts typing
                if (state.emailTouched && !state.emailValid) {
                  setState((prevState) => ({
                    ...prevState,
                    emailValid: true,
                  }))
                }
                handleFieldChange("email", e.target.value)
              }}
              onBlur={() => handleFieldBlur("email")}
              className={`signup-email-input ${state.emailTouched && !state.emailValid || submissionError ? "input-error" : ""}`}
            />
            {state.emailTouched && !state.emailValid ? (
              <div className="error">
                Invalid Email address. Please enter a valid email in this format: example@gmail.com
              </div>
            ) : null}
          </WithLabel>
          <WithLabel label="Password">
          <div className="password-field-container">
              <Textfield
                type={passwordVisible ? "text" : "password"}
                placeholder="password"
                onChange={(e) => handleFieldChange("password", e.target.value)}
                onBlur={() => handleFieldBlur("password")}
                className={`signup-password-input ${error ? "input-error" : ""}`}
              />
            <button onClick={togglePasswordVisibility} className="password-toggle-button">
              <img src={passwordVisible ? icons["eye"] : icons["eye-slash"]} alt="Toggle visibility" />
            </button>
          </div>
        </WithLabel>
          <ForgotPasswordLink />
          <AssessmentButton style={{ marginTop: "24px", width: mobile ? "100%" : "60%",  }} variant="secondary" onClick={handleSubmit} disabled={!isFormValid()}>
            Sign In
          </AssessmentButton>
          <SignUpLink searchParams={searchParams}/>
          {error ? <div className="sign-in-error">{error}</div> : null}
        </div>

        {/* Divider */}
        <div
          style={{
            display: "flex",
            flexDirection: mobile ? "row" : "column",
            alignItems: "center", // Ensures vertical centering in desktop view
            justifyContent: "center",
            margin: "20px 0",
          }}
        >
          {/* Divider to the Left - Applicable for both Desktop and Mobile */}
          <div
            style={{
              height: mobile ? "2px" : "130px",
              width: mobile ? "40%" : "2px",
              backgroundColor: "#ccc",
              alignSelf: mobile ? "center" : "stretch", // Stretch for desktop to fill container height
              margin: mobile ? "0 10px 0 0" : "0 0 0 16px",
            }}
          ></div>

          {/* Text */}
          <p
            style={{
              margin: "16px 0 16px 0",
              fontSize: "1rem",
              fontWeight: "600",
              alignSelf: "center",
              padding: "0 10px",
            }}
          >
            Or
          </p>

          {/* Divider to the Right - Applicable for both Desktop and Mobile */}
          <div
            style={{
              height: mobile ? "2px" : "130px",
              width: mobile ? "40%" : "2px",
              backgroundColor: "#ccc",
              alignSelf: mobile ? "center" : "stretch", // Stretch for desktop to fill container height
              margin: mobile ? "0 0 0 10px" : "0 0 0 16px",
            }}
          ></div>
        </div>

        {/* Right Side - oAuth */}
        <div style={{ ...style.rightContainer, flex: desktop ? 1 : "unset" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "392px",
            }}
          >
            <AuthWrapper
              onGoogle={onGoogle}
              onApple={onApple}
              onGuest={onGuest}
              desktop={desktop}
              displayTitle={false}
            />
          </div>
        </div>
      </div>
    </>
  )
}
