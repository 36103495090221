import React from "react"
import { SignInComponent } from "../components/SignInComponent"
import LayoutScreen from "../components/Layout"

export default function SignIn({ authClient }) {
  return (
    <LayoutScreen showProfile={false}>
      <SignInComponent />
    </LayoutScreen>
  )
}
