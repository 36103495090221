import { useState, useEffect } from "react"
import icons from "../icn/index"

export function Card({
  header,
  children,
  style,
  editable = false,
  onEdit = () => {},
}) {
  style = {
    background: "#FEFEFE",
    // boxShadow: "1px 1px 4px rgba(83, 66, 153, 0.12)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    padding: "16px",
    ...style,
  }
  return (
    <div style={style}>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1" }}>
          <h2
            style={{
              fontSize: "24px",
              lineHeight: "36px",
              color: "var(--primary)",
              margin: "0 0 8px",
            }}
            className="secondary"
            tabIndex="1"
          >
            {header}
          </h2>
        </div>
        <div
          style={{
            flex: "0",
            color: "rgba(83, 66, 153, 1)",
            fontSize: "16px",
            lineHeight: "18px",
            visibility: editable ? "visible" : "hidden",
          }}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
              onEdit()
            }}
          >
            <img src={icons["edit2"]} alt="" />
          </a>
        </div>
      </div>
      {children}
    </div>
  )
}

export function ConfirmationCard({
  header,
  children,
  style,
  editable = false,
  onEdit = () => {},
}) {
  style = {
    background: "#FEFEFE",
    // boxShadow: "1px 1px 4px rgba(83, 66, 153, 0.12)",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    padding: "16px",
    ...style,
  }
  return (
    <div style={style}>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1" }}>
          <h2
            style={{
              fontSize: "24px",
              lineHeight: "36px",
              color: "var(--primary)",
              margin: "0 0 8px",
            }}
            className="secondary"
            tabIndex="1"
          >
            {header}
          </h2>
        </div>
      </div>
      {children}
    </div>
  )
}

export function CardText({ children, style }) {
  return (
    <p
      style={{
        fontSize: "16px",
        lineHeight: "26px",
        color: "var(--primary)",
        fontWeight: 600,
        margin: 0,
        ...style,
      }}
    >
      {children}
    </p>
  )
}

export function CardSpan({ children, style }) {
  return (
    <p
      style={{
        fontSize: "16px",
        lineHeight: "26px",
        color: "var(--primary)",
        margin: 0,
        ...style,
      }}
    >
      {children}
    </p>
  )
}

export function clamp(min, v, max) {
    if (v < min) {
      return min
    }
    if (v > max) {
      return max
    }
    return v
  }

export function CheckoutStepSubtitle({ currentStep, totalSteps, isMobile }) {
    if (!isMobile) {
      return null
    }
  
    const stepText = `(Step: ${currentStep}/${totalSteps})`
    return <div className="progress-bar-steps">{stepText}</div>
  }

  export function Dollars({cents}) {
    return `$${cents / 100}`
  }
