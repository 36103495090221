import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import rootReducer, { appleAuthSuccess, googleAuthSuccess } from './reducers'

import { client } from './client'

const preloadedState = {
  auth: {}
}

if (sessionStorage.getItem("authed")) {
  preloadedState.auth.authed = true
}

if (sessionStorage.getItem("token")) {
  preloadedState.auth.token = JSON.parse(sessionStorage.getItem("token"))
}

const store = createStore(rootReducer, preloadedState, applyMiddleware(thunk))
store.subscribe(() => console.log(store.getState()))

document.addEventListener('AppleIDSignInOnSuccess', (event) => {
  return store.dispatch(appleAuthSuccess(event.detail.authorization))
})

document.addEventListener('AppleIDSignInOnFailure', (event) => {
  console.error(event)
})

window.addEventListener("message", (event) => {
  if (event.origin !== window.location.origin) { return }

  if (event.data.type === "googleConsentSuccess") {
    return store.dispatch(googleAuthSuccess(event.data.code))
  }
}, false);

// window.store = store // for debugging

export default store
