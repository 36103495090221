import React from "react"
import { useState, useEffect, useContext, useCallback } from "react"

import { createComponent, AssessmentScreenTitle, AssessmentButton } from "./AssessmentComponents"
import { questions } from "../spec/utils"
import images from "../img/index"
import ModalContext from "../modal"
import { BpNumbersRecentModal, BpModal, BmiModal } from "./ConfirmModals"
import { authClient } from "../client"

import { ReturnButton } from "./MoreInfo"
import useResponsive from "../responsive"
import ProgressBar from "./ProgressBar"
import TitleBanner from "./TitleBanner"

import "../css/BaseScreen.css"

const heros = {
  stop: <img className="kickout-stop" src={images.stop} alt="" style={{ width: "100px", height: "auto" }} />,
  adbu: <img className="adbu-stop" src={images.adbu} alt="" style={{ width: "270px", height: "auto" }} />,
}

export function BaseScreen({
  heroImage,
  title,
  children,
  showButton = true,
  underButton,
  style = {},
  onSubmit,
  state = {},
  backButtonText = "Back",
  buttonText = "Next",
  disableContinue,
  components = {},
  subtitle,
  onBack,
  showBackButton,
  beforeTitle,
  showButtonContainer = true,
}) {
  const isDesktop = useResponsive().desktop

  const outerStyle = {
    ...style.outer,
  }

  const innerStyle = {
    ...style.inner,
  }

  const titleStyle = style.title || {}
  if (subtitle && !isDesktop) {
    titleStyle.marginBottom = "8px"
  }

  const buildTitle = () =>
    title && (typeof title == "string" ? <AssessmentScreenTitle text={title} style={titleStyle} /> : createComponent(title))

  // TODO: could be a conditional override defined in the spec
  const buildBackButton = () =>
    state.state === "returningedit" ? (
      <ReturnButton />
    ) : (
      <AssessmentButton onClick={onBack} variant="primary outline thin" style={{ flex: "0 1 400px" }}>
        {backButtonText}
      </AssessmentButton>
    )

  return (
    <div className="outer" style={outerStyle}>
      {heroImage}
      {beforeTitle}
      {buildTitle()}
      {subtitle && <span style={{ padding: "0 32px 16px", ...style.subtitle }}>{subtitle}</span>}
      <div className="inner" style={innerStyle}>
        {children}
      </div>

      { showButtonContainer && (
        <div className="button-container">
          {showBackButton && buildBackButton()}
          {showButton && (
            <AssessmentButton onClick={onSubmit} variant="secondary" disabled={disableContinue} style={{ width: "100%" }}>
              {buttonText}
            </AssessmentButton>
          )}
        </div>
      )}
    </div>
  )
}

export function ModalBaseScreen({
  heroImage,
  title,
  children,
  showButton = true,
  underButton,
  style = {},
  buttonStyle = {},
  onSubmit,
  state = {},
  backButtonText = "Back",
  buttonText = "Next",
  disableContinue,
  components = {},
  subtitle,
  onBack,
  showBackButton,
  beforeTitle,
  showButtonContainer = true,
}) {
  const isDesktop = useResponsive().desktop

  const innerStyle = {
    ...style.inner,
  }

  const titleStyle = style.title || {}
  if (subtitle && !isDesktop) {
    titleStyle.marginBottom = "8px"
  }

  const buildTitle = () =>
    title && (typeof title == "string" ? <AssessmentScreenTitle text={title} style={titleStyle} /> : createComponent(title))

  // TODO: could be a conditional override defined in the spec
  const buildBackButton = () =>
    state.state === "returningedit" ? (
      <ReturnButton />
    ) : (
      <AssessmentButton onClick={onBack} variant="primary outline thin" style={{ flex: "0 1 400px" }}>
        {backButtonText}
      </AssessmentButton>
    )

  return (
    <div className="outer-modal-container">
      {heros[heroImage]}
      {beforeTitle}
      {buildTitle()}
      {subtitle && <span style={{ padding: "0 32px 16px", ...style.subtitle }}>{subtitle}</span>}
      <div className="inner" style={innerStyle}>
        {children}
      </div>

      { showButtonContainer && (
        <div className="button-container">
          {showBackButton && buildBackButton()}
          {showButton && (
            <AssessmentButton
              onClick={!disableContinue ? onSubmit : null}
              variant="secondary"
              disabled={disableContinue}
              style={{ width: "100%", ...buttonStyle }}
            >
              {buttonText}
            </AssessmentButton>
          )}
        </div>
      )}
    </div>
  )
}

export function KickoutScreen({
  id,
  title,
  text,
  heroImage,
  showStopSignImage = true,
  onClick,
  onEvent,
  fromAdbuScreen = false,
  fromAdopbuScreen = false,
  children,
}) {
  useEffect(() => {
    onEvent({ componentId: id, type: "SCREEN_DISPLAYED" })
  }, [id])

  // remove auth if kicked out
  useEffect(() => {
    authClient.clearToken()
  }, [])

  const getHeroImage = () => {
    if (fromAdbuScreen || fromAdopbuScreen) return "adbu"
    if (heroImage) return heroImage
    return "stop"
  }

  const actualHeroImageKey = getHeroImage()
  const actualHeroImage = heros[actualHeroImageKey]

  console.log("Hero Image Key:", actualHeroImageKey) // This will display the current key.
  console.log("Hero Image URL/Element:", actualHeroImage) // This should display the actual image URL or element.

  // const stopSign = showStopSignImage && !fromAdbuScreen && !fromAdopbuScreen ? (
  //     <div className="kickout-stop">
  //         <img src={images.stop} alt="" />
  //     </div>
  // ) : null;

  return (
    <BaseScreen
      title={title}
      heroImage={actualHeroImage}
      onClick={onClick}
      showButton={false}
      showBackButton={false}
      // beforeTitle={stopSign}
      showButtonContainer={false}
    >
      {children}
    </BaseScreen>
  )
}

const confirmModals = {
  bpnumbers: BpModal,
  knowBpNumbers: BpNumbersRecentModal,
  BMIObesityScreen: BmiModal,
}

export function Screen({
  id,
  onEvent,
  onSubmit,
  showContinueButton,
  style,
  title,
  subtitle,
  children,
  heroImage,
  underButton,
  buttonText,
  confirm,
  answers,
  getValue,
  screenSpec,
  onBack,
  showBackButton,
  state,
  spec,
  progress,
}) {
  useEffect(() => {
    onEvent({ componentId: id, type: "SCREEN_DISPLAYED" })
  }, [id])

  const setModal = useContext(ModalContext)

  const confirmBeforeSubmit = (a) => {
    const errs = validate(screenSpec, answers)
    if (Object.keys(errs).length > 0) {
      return onSubmit(a) // prevents modal from showing if validation errors
    }

    for (let i = 0; i < confirm.length; i++) {
      const c = confirm[i]
      const fn = new Function(`return state => ${c.cond}`)()
      if (fn(answers)) {
        const key = c.modal || id

        const handleConfirm = (v) => {
          setModal()
          onEvent({ type: "CONFIRM", componentId: `${key}Confirm` })
          onSubmit({ ...a, ...v })
        }

        const closeModal = () => setModal()

        const outerStyle = {
          display: "flex",
          flexDirection: "column",
          borderRadius: "5px",
        }

        const Component = confirmModals[key]
        const createModal = () => (
          <div style={outerStyle}>
            <Component
              answers={answers}
              onConfirm={handleConfirm}
              onClose={closeModal}
              onEvent={onEvent}
              getValue={getValue}
            />
          </div>
        )

        setModal(createModal)
        return
      }
    }

    onSubmit(a)
  }

  const handleSubmit = confirm ? confirmBeforeSubmit : onSubmit
  const remaining = questions(screenSpec).filter((q) => answers[q.id] === undefined)
  const isPrivacyNoticeScreen = id === "PrivacyNoticeScreen"
  const SignUpScreen = id === "SignUpScreen"
  const current = state.index + 1
  const total = spec.children.length - 6
  return (
    <>
      { isPrivacyNoticeScreen 
        ? <TitleBanner text="Privacy Notice" />
        : (!SignUpScreen && <ProgressBar current={current} total={total} />
        )
      }

      <BaseScreen
        title={title}
        subtitle={subtitle}
        heroImage={heroImage}
        style={style || {}}
        showButton={showContinueButton === true}
        disableContinue={remaining.length}
        underButton={underButton || {}}
        buttonText={buttonText || "Next"}
        onBack={onBack}
        showBackButton={showBackButton}
        state={state}
        onSubmit={() => handleSubmit()}
      >
        {children}
      </BaseScreen>
    </>
  )
  //return (
  //  <BaseScreen title={screenSpec.title}
  //   showButton={screenSpec.showContinueButton == true} onClick={() => onSubmit()} buttonText="Next">
  //    { React.createElement(React.Fragment, {}, ...children) }
  //  </BaseScreen>
  //)
}

// internal assessment state to protobuf assessment state
export function encodeScreenState(state) {
  return { ...state, answers: JSON.stringify(state.answers) }
}

// protobuf assessment state to internal assessment state
export function decodeAssessmentState(pbstate) {
  return {
    index: pbstate.index,
    state: pbstate.state,
    kickoutReason: pbstate.kickoutreason,
    isCompleted: pbstate.iscompleted,
    completed: pbstate.completed,
    answers: JSON.parse(pbstate.answers),
    isTaking: pbstate.state === "assessment",
    isReviewing: pbstate.state === "editablesummary",
    isEditing: pbstate.state === "edit" || pbstate.state === "returningedit",
    isKickout: pbstate.state === "kickout",
    isOutcome: pbstate.outcome !== "",
    outcome: pbstate.outcome,
    editIndex: pbstate.editindex,
  }
}

// validate questions starting from root and return any errors
export function validate(root, answers) {
  // console.log("validating", root, answers)
  const errs = {}
  for (const q of questions(root)) {
    // yes/no buttons submit their answers at the same time the screen is submitted
    if (q.type === "YesNoButtons" || q.type === "PrivacyNotice") continue

    const answer = answers[q.id]
    if (answer === undefined) {
      errs[q.id] = "This question is required"
      continue
    }

    if (q.validation) {
      for (const validation of q.validation) {
        const fn = new Function(`return answer => ${validation.cond}`)()
        if (fn(answer)) {
          errs[q.id] = validation.err
          break
        }
      }
    }
  }
  return errs
}

export function Assessment({ spec, state, showBackButton, onSubmit, onEvent, onBack }) {
  const [auditTrail, setAuditTrail] = useState([]) // trail of events

  // todo: possibly make exceptions to conditional screens so it doesnt blow everything up in the summary screen
  const initScreenState = useCallback(() => {
    let storedState = sessionStorage.getItem("screenState")

    if (storedState && storedState !== "undefined") {
      const parsedState = JSON.parse(storedState)
      if (Object.keys(parsedState.answers).length > 0) {
        return parsedState
      }
    }

    const screenState = { answers: {} }
    questions(spec.children[state.index]).forEach((q) => {
      if (state.answers[q.id] !== undefined) {
        screenState.answers[q.id] = state.answers[q.id]
      }
    })

    return screenState
  }, [state.index, state.answers, spec.children])

  const setScreenStateWithStorage = (newState) => {
    sessionStorage.setItem("screenState", JSON.stringify(newState))
    setScreenState(newState)
  }

  const [screenState, setScreenState] = useState(initScreenState())
  const [shouldShowErrs, setShouldShowErrs] = useState(false)

  const screenErrs = validate(spec.children[state.index], screenState.answers)

  useEffect(() => {
    setScreenStateWithStorage(initScreenState())
  }, [initScreenState])

  const handleEvent = (e) => {
    if (e.type === "UPDATED_ANSWER") {
      const updatedState = { answers: { ...screenState.answers, [e.componentId]: e.newValue } }
      setScreenStateWithStorage(updatedState)
    }
    setAuditTrail((t) => [...t, e])
    onEvent(e)
  }

  const handleSubmit = (a) => {
    // yes/no buttons submit their answers at the same time the screen is submitted
    const all = { answers: { ...screenState.answers, ...a } }
    console.log("handleSubmit", all)
    const errs = Object.keys(screenErrs).length > 0
    if (errs) {
      if (!shouldShowErrs) {
        setShouldShowErrs(true)
      }
      return
    }

    if (shouldShowErrs) {
      setShouldShowErrs(false)
    }

    onSubmit(all)
  }

  const hideErrs = () => {
    setShouldShowErrs(false)
  }

  const screenSpec = spec.children[state.index]
  const getValue = (qid) => screenState.answers[qid]
  const props = {
    isLoading: false,
    onSubmit: handleSubmit,
    onEvent: handleEvent,
    getValue,
    answers: { ...state.answers, ...screenState.answers },
    shouldShowErrs,
    screenErrs,
    hideErrs,
    screenSpec,
    onBack,
    showBackButton,
    state,
    spec,
    progress: (state.index / spec.children.length) * 100,
  }

  return createComponent(screenSpec, props)
}

// function findMetricValueForKey(metrics, key) {
//   const screenIndices = Object.keys(metrics)
//   for (let i = 0; i < screenIndices.length; i++) {
//     const screenIndex = screenIndices[i]
//     const metricsForScreen = metrics[screenIndex]
//     const value = metricsForScreen[key]
//     if (value !== undefined) {
//       return value
//     }
//   }
//   return undefined
// }
