/** @jsxImportSource @emotion/react */
import { useState } from "react";
import RouterLink from './RouterLink'
import StendraLogo from "../img/avanafil-logo.svg";
import BackArrow from "../icn/back-arrow.svg"
import "../css/NavBar.css"
import "../css/Button.css";

function CooldownButton({ type, variant, children, onClick, ...rest }) {
  const [cooldownActive, setCooldownActive] = useState(false);

  const { disabled, ...newRest } = rest;
  if (disabled !== undefined) rest = newRest;

  const handleClick = () => {
    setCooldownActive(true);
    onClick();

    setTimeout(() => {
      setCooldownActive(false);
    }, 1000); // configure cooldown time here
  }

  return (
    <button
      className={`btn ${variant}`}
      disabled={cooldownActive || disabled}
      {...rest}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

function NavBar({
  variant,
  onBack,
  showBackButton = false,
  showProfile = true,
  style = {},
  displayTextBtn = true,
  buttonText = "Previous Question"
}) {
  return (
    <header className={`header ${variant}`}>
      <div className="header-container">
        <nav aria-label="back navigation" 
          className={showBackButton ? "show" : null}
          style={{ display: showBackButton ? 'flex' : 'none' }}
        >
          <ul>
            <li>
              <CooldownButton 
                onClick={() => onBack()} 
                aria-label="previous question"
                variant="navbar-back-button"
              >
                <img src={BackArrow} alt="" />
                { displayTextBtn && (
                  <span aria-hidden="true">
                    {buttonText}
                  </span>
                )}
              </CooldownButton>
            </li>
          </ul>
        </nav>

        <RouterLink to="/" className="logo-link" state={{ canNavigate: true }}>
          <img src={StendraLogo} style={{ height: "100%" }} className="logo" alt="Stendra Logo" />
        </RouterLink>
      </div>
    </header>
  )
}

export default NavBar
