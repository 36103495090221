import arrowBack from "./arrow-back.svg";
import calendar from "./calendar.svg";
import chevronDown from "./chevron-down.svg";
import edit from "./edit.svg";
import remove from "./remove.svg";
import edit2 from "./edit2.svg";
import email from "./email.svg";
import eye from "./eye.svg";
import eyeSlash from "./eye-slash.svg";
import gift from "./gift.svg";
import greenCheck from "./green-check.svg";
import link from "./link.svg";
import lock from "./lock.svg";
import none from "./none.svg";
import pill from "./pill.svg";
import pillNo from "./pill-no.svg";
import profile from "./profile.svg";
import profileWhite from "./profile-white.svg";
import redX from "./red-x.svg";
import ruler from "./ruler.svg";
import report from './report.svg'
import scale from "./scale.svg";
import smokingNo from './smoking-no.svg'
import smokingOccasional from './smoking-occasional.svg'
import smokingRarely from './smoking-rarely.svg'
import smokingRegular from './smoking-regular.svg'
import pulse from './pulse.svg'
import pulseStrikethrough from './pulse-strikethrough.svg'
import moreinfo from './more-info-2.svg'
import arrowDown from './arrow-down.svg'
import circleExclamation from './circle-exclamation.svg'

const icons = {
    'arrow-back': arrowBack,
    'arrow-down': arrowDown,
    'calendar': calendar,
    'chevron-down': chevronDown,
    'edit': edit,
    'edit2': edit2,
    'email': email,
    'eye': eye,
    'eye-slash': eyeSlash,
    'gift': gift,
    'green-check': greenCheck,
    'link': link,
    'lock': lock,
    'none': none,
    'pill': pill,
    'pill-no': pillNo,
    'profile': profile,
    'profile-white': profileWhite,
    'remove': remove,
    'report': report,
    'red-x': redX,
    'ruler': ruler,
    'scale': scale,
    'smoking-no': smokingNo,
    'smoking-occasional': smokingOccasional,
    'smoking-rarely': smokingRarely,
    'smoking-regular': smokingRegular,
    'pulse': pulse,
    'pulse-strikethrough': pulseStrikethrough,
    'circle-exclamation': circleExclamation
}

export default icons

export { gift, moreinfo }
