import "../css/Typography.css"
import "../css/Footer.css"

export default function Footer({ style = {} }) {
  const participantID = sessionStorage.getItem("participantID")
  return (
    <div className="footer-container" style={style}>
      <div className="privacy body-small">Privacy Notice</div>
      <div className="divider body-small"> | </div>
      <div className="phone body-small">1-800-XXX-XXXX</div>
      <div className="divider body-small"> | </div>
      <div className="userId body-small">Participant ID: {participantID}</div>
    </div>
  )
}
