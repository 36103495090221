import { useNavigate } from "react-router-dom"
import "../css/SignUp.css"

const style = {
  layout: {
    layoutMain: {
      background: "#F9F9F9",
    },
    layoutMainMobileInner: {
      width: undefined,
    },
  },
  content: {
    title: {
      fontSize: "27px",
    },
    termsText: {
      fontSize: "16px",
      lineHeight: "20px",
      color: "rgba(99, 99, 99, 1)",
      textDecorationSkipInk: "none",
    },
  },
  divider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0", // Updated margin
  },
  leftContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "0 20px", // Add padding for better spacing
  },
  rightContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
}

const TermsLink = ({ children }) => {
  const style = {
    textDecoration: "underline",
    color: "var( --hyperlink)",
    fontWeight: "inherit",
  }
  return (
    <a href="#" onClick={(e) => e.preventDefault()} style={style}>
      {children}
    </a>
  )
}

const ForgotPasswordLink = ({ children }) => {
  const navigate = useNavigate()
  const toForgotPassword = (e) => {
    e.preventDefault()
    navigate("/forgot-password")
  }
  const linkStyle = {
    cursor: "pointer",
    fontFamily: "Open Sans",
    textDecoration: "underline",
    color: "var(--hyperlink)",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "2rem",
    letterSpacing: "-0.011em",
    textAlign: "left",
  }

  return (
    <div className="forgot-password-link">
      <a onClick={toForgotPassword} href="" style={linkStyle}>
      Forgot Password?
      </a>
    </div>
  )
}

const SignInLink = ({ children, searchParams}) => {
  const navigate = useNavigate()
  const toSignIn = (e) => {
    e.preventDefault()

    const params = searchParams.toString()
    if (params) {
      navigate(`/sign-in?${params}`)
    } else {
      navigate("/sign-in")
    }
  }
  const style = {
    fontFamily: "Open Sans",
    textDecoration: "underline",
    color: "var( --hyperlink)",
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: "2rem",
    letterSpacing: "-0.011em",
    textAlign: "center",
    cursor: "pointer",
  }
  return (
    <span onClick={toSignIn} style={style}>
      {children}
    </span>
  )
}

const SignUpLink = ({ children, searchParams}) => {
  const navigate = useNavigate()
  const toCreateAccount = (e) => {
    e.preventDefault()

    const params = searchParams.toString()
    if (params) {
      navigate(`/create-account?${params}`)
    } else {
      navigate("/create-account")
    }
  }
  const linkStyle = {
    cursor: "pointer",
    fontFamily: "Open Sans",
    textDecoration: "underline",
    color: "var(--hyperlink)",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "2rem",
    letterSpacing: "-0.011em",
    textAlign: "left",
  }

  return (
    <div className="sign-up-link">
      <a onClick={toCreateAccount} href="" style={linkStyle}>
      Don’t have an account? Create an account
      </a>
    </div>
  )
}

// Validation functions for email
const isEmailValid = (email) => {
  // const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  const trimmedEmail = email.trim() // Trim white spaces
  return emailPattern.test(trimmedEmail)
}

// Validation check ensuring "Confirm email" matches the original email
const isConfirmEmailValid = (email, confirmEmail) => {
  return email === confirmEmail
}

// Validation functions for password
const isPasswordValid = (password) => {
  // Define patterns for characters commonly replaced in Leetspeak
  const a = "[a@4]"
  const s = "[s$5]"
  const o = "[o0]"
  const i = "[i1!]"
  const e = "[e3]"
  const t = "[t7]"
  const d = "[d]"

  // List of base words to transform into regex patterns including Leetspeak variations
  const baseWords = [
    "password", 
    "admin", 
    "email",
    "user",
    "avanafil",
    "petros", 
    "ideaevolver",
    "idea",
    "evolver",
  ]

  // Transform base words into regex patterns considering Leetspeak variations
  const patterns = baseWords.map(word => {
    // Replace characters in base words with their corresponding patterns
    return word.replace(/a/gi, a)
               .replace(/s/gi, s)
               .replace(/o/gi, o)
               .replace(/i/gi, i)
               .replace(/e/gi, e)
               .replace(/t/gi, t)
               .replace(/d/gi, d)
  })

  // Join the patterns into a single regex pattern
  const regexPattern = patterns.join("|")
  const commonWordsRegex = new RegExp(regexPattern, "i")

  // Validations
  const validations = {
    number: /\d/.test(password),
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    minimum: password.length >= 8,
    maximum: password.length <= 64,
    commonWords: !commonWordsRegex.test(password),
  }

  // Check if all validations pass
  const valid = Object.values(validations).every(Boolean)
  return { valid, ...validations }
}


const getRuleDescription = (ruleKey) => {
  const descriptions = {
    number: "Contain at least 1 number",
    uppercase: "Contain at least 1 uppercase letter",
    lowercase: "Contain at least 1 lowercase letter",
    minimum: "Be a minimum of 8 characters",
    maximum: "Be less than 65 characters",
    commonWords: "Not contain words such as password, your name, email or other common words",
  }
  return descriptions[ruleKey] || 'Unknown rule'
}


// Validation check ensuring "Confirm password" matches the original password
const isConfirmPasswordValid = (password, confirmPassword) => {
  return password === confirmPassword
}

export { style, TermsLink, SignInLink, SignUpLink, ForgotPasswordLink, isEmailValid, isConfirmEmailValid, isPasswordValid, getRuleDescription, isConfirmPasswordValid }
