import React from 'react'

import '../css/Progress.css'

const ProgressBar = ({ current, total }) => {
    const progress = Math.min(((current / total) * 100).toFixed(2), 100)
    return(
        <div className="progress-mobile">
            <div className="inner-bar" data-testid="progress-bar-fill" style={{width: `${progress}%`, transition: 'width 0.7s'}}></div>
        </div>
    )
}

export default ProgressBar;