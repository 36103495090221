import { useState, useEffect } from 'react'
import { Checkbox } from '../../components/AssessmentComponents'
import { BillingAddressInput } from './BillingInformation'

// CSS
import "../../css/PaymentInformation.css"


function NumberFieldWithLabel({ id, label, err, onChange, value, required=false, icons=false, errMsg }) {
  const [touched, setTouched] = useState(false)

  const errorStyling = required && (!value || err)
  const shouldDisplayErrorMsg = errorStyling && touched
  const displayErrorClassName = shouldDisplayErrorMsg ? "error-field-style" : ""

  return (
      <div>
        <label htmlFor={id}>
          {label}
          {required && (<span className="required-field">*</span>)}
        </label>
        <div className={`text-field ${icons ? "input-container" : ""} ${displayErrorClassName}`}>
          <input type="text" onChange={onChange} value={value} onBlur={() => setTouched(true)}/>
          {icons && (
            <span className="icon-container">
              <i className="amex-card-icon"></i>
              <i className="visa-card-icon"></i>
              <i className="mastercard-icon"></i>
            </span>
          )}
        </div>
        {shouldDisplayErrorMsg && (
          <span className="error-text">{errMsg}</span>
        )}
    </div>
  )
}


const CardComponents = ({ onPaymentInfo, setPaymentMethodDetails }) => {
  const initialCardDetails = JSON.parse(sessionStorage.getItem('paymentDetails')) || { cardNumber: '', cardExpiry: '', cardCvc: '' }

  const [state, setState] = useState(initialCardDetails)
  
  const update = (k, v) => {
    const newState = { ...state, [k]: v }
    setState(newState)
    // Save to sessionStorage
    sessionStorage.setItem('paymentDetails', JSON.stringify(newState))
  }
  
  // Updating the state as we change the different input fields
  const handleState = (field, value) => {
    const text = value.trimStart()
    update(field, text)
  }

  const checkCardNumber = number => {
    const regex = /^[0-9]{16}$/
    return regex.test(number)
  }

  // Note: For human factors testing, we need to check if `checkCVCCode` has either 3 or 4 digits
  // and if `checkExpirationDate` has the MM/YY format and the input date is not past the current date.
  // TODO: Both of these would be replaced with Stripe's components validation
  const checkExpirationDate = number => {
    let parts = number.split("/")

    // Show error if more than 5 chars
    if (number.length > 5 || parts[0]?.length > 2 || parts[1]?.length > 2) return false

    // Assuming current date
    let currentDate = new Date()
    let currentYear = currentDate.getFullYear()
    let currentMonth = currentDate.getMonth() + 1 // January is 0 in JavaScript
    
    // Parse the input
    let expiryYear = parseInt(parts[1]) + 2000 // Assuming YY means 20YY
    let expiryMonth = parseInt(parts[0])

    const isValidMonth = expiryMonth <= 12
    const isCurrentYear = expiryYear === currentYear
    const isValidExpiryYear = expiryYear >= currentYear

    const isValidExpiryDate = isValidMonth && isValidExpiryYear && (isCurrentYear ? expiryMonth >= currentMonth : isValidMonth)

    return isValidExpiryDate
  }
  
  const checkCVCCode = number => {
    const regex = /^[0-9]{3,4}$/
    return regex.test(number)
  }
  
  let completeValidation = checkCardNumber(state.cardNumber) && checkExpirationDate(state.cardExpiry) && checkCVCCode(state.cardCvc)

  useEffect(() => {
    onPaymentInfo(completeValidation)
  }, [completeValidation])

  const determineCardBrand = (number) => {
      // Implement logic to determine card brand
      if (number.startsWith("3")) return "amex"
      if (number.startsWith("4")) return "visa"
      if (number.startsWith("5")) return "mastercard"
      if (number.startsWith("6")) return "discover"

      // Add other card types as needed
      return "Unknown"
  };

  useEffect(() => {
      if (state.cardNumber.length >= 16) { // Check if the card number is sufficiently long
          const brand = determineCardBrand(state.cardNumber)
          const last4 = state.cardNumber.slice(-4);
          setPaymentMethodDetails({ brand, last4 })
      }
  }, [state.cardNumber, setPaymentMethodDetails])
  
  return (
    <div className="info-grid-container">
      <div className="info-grid-subcontainer_col1">
        <NumberFieldWithLabel 
          id="cardNumber"
          label="Card Number"
          onChange={e => handleState("cardNumber", e.target.value)}
          value={state.cardNumber}
          required={true}
          icons={true}
          err={!checkCardNumber(state.cardNumber)}
          errMsg="Please enter a valid card number with no spaces"
        />
      </div>
      <div className="info-grid-subcontainer_col2">
        <NumberFieldWithLabel
          id="cardExpiry"
          label="Expiration date (MM/YY)"
          onChange={e => handleState("cardExpiry", e.target.value)}
          value={state.cardExpiry}
          required={true}
          err={!checkExpirationDate(state.cardExpiry)}
          errMsg="Please enter a valid expiration date"
        />
        <NumberFieldWithLabel 
          id="cardCvc"
          label="CVC"
          onChange={e => handleState("cardCvc", e.target.value)}
          value={state.cardCvc}
          required={true}
          err={!checkCVCCode(state.cardCvc)}
          errMsg="Please enter a 3 or 4 digit CVC"
        />
      </div>
    </div>
  )
}


export function PaymentMethod({ setNextButtonState, setPaymentMethodDetails, billingAddress, onCheck, onPaymentInfo, onCompleteChange, onChangeBillingAddress, paymentAddressSame }) {

  let completeValidation = paymentAddressSame || billingAddress.complete
    // Save the paymentAddressSame state to sessionStorage when it changes
    useEffect(() => {
      sessionStorage.setItem('paymentAddressSame', JSON.stringify(paymentAddressSame))
    }, [paymentAddressSame])
  
    // Initialize paymentAddressSame from sessionStorage
    useEffect(() => {
      const saved = JSON.parse(sessionStorage.getItem('paymentAddressSame'))
      if (saved !== null) {
        onCheck(saved)
      }
    }, [])
  useEffect(() => {
    onCompleteChange(completeValidation)
  }, [completeValidation])

  return (
    <main>
      <div>
        <Checkbox 
          title="Billing address is same as shipping address"
          value="same"
          variant="primary"
          className="same-address-checkbox"
          onCheck={onCheck}
          checked={paymentAddressSame}
        />

      {!paymentAddressSame && (
        <div>
          <h2 className="billing-address-title">Enter Billing address</h2>
          <BillingAddressInput onChange={onChangeBillingAddress} setNextButtonState={setNextButtonState}/>
          <hr className="components-divider"/>
        </div>
      )}

      </div>
      <CardComponents onPaymentInfo={onPaymentInfo} setPaymentMethodDetails={setPaymentMethodDetails} />
    </main>
  )
}

