import '../css/TextField.css'
import infocircle from "../icn/infocircle.svg"


export function WithLabel({id, label, children}) {
  return (
    <div>
      <label htmlFor={id} style={{display: 'block', fontSize: '18px', marginBottom: '8px'}}>{label}</label>
      {children}
    </div>
  )
}

// An input field with a note below it.
export function WithNote({ id, label, note, children }) {
  return (
    <div>
      <label htmlFor={id} style={{ display: 'block', fontSize: '18px', marginBottom: '8px' }}>{label} <span className="required-asterisk">*</span></label>
      {children}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={infocircle} alt="Info Icon" style={{ marginRight: '5px', marginTop: '8px', alignSelf: 'flex-start'  }} /> 
        <p style={{ fontSize: '1rem', color: '#071439', marginTop: '8px' }}>{note}</p> 
      </div>
    </div>
  );
}

export function RequiredField({ label, children }) {
  return (
    <WithLabel label={<>{label} <span className="required-asterisk">*</span></>} >
      {children}
    </WithLabel>
  );
}

export default function Textfield({ id, style={}, variant, ...rest }) {
  return (
    <div className={`text-field ${variant}`} style={style.root}>
      { rest.icon && <img className="icon" src={rest.icon} alt="" />}
      <input type="text" id={id} style={{ paddingRight: rest.units ? "64px" : "19px", paddingLeft: rest.icon ? "56px" : "19px", ...style.input}} {...rest} />
      { rest.units && <span className="units" style={style.units}>{rest.units}</span> }
    </div>
  );
}
