import { RadioList } from '../../components/AssessmentComponents'

export function SelectProduct({products, value, onSelect}) {
    const options = products.map(p => ({value: p.id, title: p.description, rightText: `$${p.price / 100}`, productData: p }))

    const handleSelect = (productId) => {
      const selectedProduct = products.find(p => p.id === productId);
      onSelect(selectedProduct); // Pass the selected product object
  };
    return (
      <div>
        <RadioList id="select-product" data-testid="select-product" options={options} value={value.id || ''} onSelect={handleSelect}/>
      </div>
    )
  }