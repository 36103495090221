import "../css/WarningModal.css"


// Confirming modal is displayed when the user needs to confirm an action as in `Review Answers` screen
// Note: Styling of the text will vary if there is a title or not in the modal (per agreement with designer)
export const AssessmentConfirmingModal = ({ handleClose, handleContinue, title, text, text2 }) => {
  return (
    <main className="assessment-confirming-modal"> 
      <section className="warning-modal-section">
        <div className="close-button-container">
          <i className="close-button-icon" onClick={handleClose} role="button" aria-label="Close modal" />
        </div>
        { title && (<div className="warning-modal-title">{title}</div>)}

        <p className={`${title ? "warning-modal-text" : "confirming-modal-text"}`}>
          {text}
        </p>
        { text2 && (
          <p className={`${title ? "warning-modal-text" : "confirming-modal-text"}`}>{text2}</p>
        )}

        <div className="warning-modal-buttons">
          <button
            className="confirm-button"
            aria-label="Confirm"
            onClick={e => handleContinue(e)}
          >
            I Confirm
          </button>
        </div>
      </section>
    </main>
  )
}
