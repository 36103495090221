import '../css/Progress.css'

export default function Progress({cur, steps, displayName}) {
    const lastStepIndex = steps.length - 1; // Index of the last step
    const children = steps.map((s, i) => {
        let state = ''
        if (i < cur || (cur === lastStepIndex && i === cur)) {
            state = 'completed'
        } else if (i === cur) {
            state = 'current'
        } else {
            state = 'incomplete'
        }
        return <Step key={s} title={s} state={state} displayName={displayName}/>
    })

    const numSpaces = steps.length - 1
    const progress = Math.min(((cur / numSpaces) * 100).toFixed(2), 100)

    return (
        <div className="progress">
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", position: "relative"}}>
                <div style={{position: "absolute", display: "flex", justifyContent: "space-between", width: "100%"}}>
                    {children}
                </div>
                <div style={{height: "2px", background: "#FFC45C"}}>
                    <div className="inner-bar" style={{width: `${progress}%`}}></div>
                </div>
            </div>
        </div>
    )
}

function Step({title, state, displayName}) {
    return (
        <div className={`progress-step ${state}`}>
            {displayName && (<span>{title}</span>)}
        </div>
    )
}

export function SmoothProgress({progress}) {
    return (
        <div className="progress-mobile">
            <div className="inner-bar" style={{width: `${progress}%`}}></div>
        </div>
    )
}