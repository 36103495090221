import React from "react"
import { SignUpComponent } from "../components/SignUpComponent"
import LayoutScreen from "../components/Layout"

export default function CreateAccount({ authClient }) {
  return (
    <LayoutScreen showProfile={false}>
      <SignUpComponent />
    </LayoutScreen>
  )
}
