/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import "../css/Button.css";

export default function Button({ type, variant, children, ...rest }) {
  const [cooldownActive, setCooldownActive] = useState(true);

  const { disabled, ...newRest } = rest;
  if (disabled !== undefined) rest = newRest;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCooldownActive(false);
    }, 1000); // configure cooldown time here

    // This function will be called when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return (
    <button
      className={`btn ${variant}`}
      disabled={cooldownActive || disabled}
      {...rest}
    >
      {children}
    </button>
  );
}
