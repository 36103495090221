import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import router from "../history"

// Local dependencies and utilities
import { authClient } from "../client"
import { assessmentStarted, setAssessmentNumber } from "../reducers"
import Footer from "../components/Footer"
import landingPageImage from "../img/landing-page-image.png" // Tell webpack this JS file uses this image
import StendraLogo from "../img/avanafil-logo.svg"
// import { assessmentStarted, reserveAssessmentNumber, setAssessmentNumber } from "../reducers"

// CSS
import "../css/LandingPage.css"

// Actual landing page
export default function LandingPage({ client }) {
  const dispatch = useDispatch()
  // const assessment = useSelector((state) => state.assessment) // TODO: figure out useSelector
  // const auth = useSelector(state => state.auth) TODO: figure out if we need this
  const [searchParams, setSearchParams] = useSearchParams()
  const clinician = searchParams.get("clinician")
  // const [participantID, setParticipantID] = useState(null)
  // const recordMetric = useMetrics()
  const invite = searchParams.get("invite")

  const navigate = useNavigate()

  useEffect(() => {
    if (invite) {
      // if there's already an invite in the param, just use that and save in session storage
      sessionStorage.setItem("invite", invite)
    } else {
      // otherwise try to load the invite and set in the param if one exists
      const stored = sessionStorage.getItem("invite")

      // don't set the invite if there's a clinician token
      if (stored && !clinician) {
        setSearchParams((searchParams) => {
          const others = {}
          for (const [key, value] of searchParams) {
            others[key] = value
          }
          return {...others, invite: stored}
        })
      }
    }
  }, [])

  // TODO: integrate this when we get clinicians
  useEffect(() => {
    sessionStorage.removeItem("screenState")
    sessionStorage.removeItem("dflRead")
    sessionStorage.removeItem("OutcomeFlow_showDFL")
    sessionStorage.removeItem("OutcomeFlow_inputting")
    sessionStorage.removeItem("OutcomeFlow_readDFL")
    sessionStorage.removeItem("OutcomeFlow_showSignInScreen")
    sessionStorage.removeItem("participantID")
    sessionStorage.removeItem("continuewithEmail")
    sessionStorage.removeItem("assessmentID")
  }, [])

  useEffect(() => {
    authClient.getToken().catch((error) => {
      console.error("Error getting token", error)
    }, [])
  }, [invite])

  useEffect(() => {
    if (clinician) {
      client.getClinicianAssessment({ token: clinician }).then((resp) => {
        const { assessmentId, assessmentNumber } = resp
        dispatch(setAssessmentNumber(assessmentId, assessmentNumber))
      })
    } else {
      // dispatch(reserveAssessmentNumber())
    }
  }, [clinician, dispatch, client])

  const start = async () => {
    // check for clinician and set token if present
    if (clinician) {
      const { assessmentId, authToken } = await client.takeClinicianAssessment({
        token: clinician,
      })
      await authClient.setToken({ token: authToken })
      router.push(`/assessment/${assessmentId}`)
      return
    }
    if (!invite) {
      // TODO: maybe have the base URL with no invite or clinician query param render something that says they cant take the assessment
      return
    }
    if (invite) {
      try {
        await authClient.getToken()
        dispatch(assessmentStarted())
      } catch (error) {
        console.error("Error starting assessment", error)
      }
    }
  }

  const toSignIn = () => {
    navigate("/sign-in")
  }

  return (
    <div className="welcome">
      <header className="welcome-header">
        <img src={StendraLogo} className="welcome-logo" alt="Stendra Logo" />
        <button onClick={toSignIn} className="signin-button">
          Sign in
        </button>
      </header>

      <main className="content">
        <section className="body">
          <h1 className="landing-title">Welcome to the Avanafil OTC Self-Check</h1>
          <p>
            This self-check must be completed to determine if Avanafil OTC is appropriate for you to use. It is only for men
            18 years of age and older. If the results of this self-check confirm your eligibility, you will be granted access
            to purchase Avanafil OTC.
          </p>
          <p className="legal">For investigational use only</p>
        </section>
        <section>
          <div className="cta">
            <p>Everything in this self-check is secure and private. It is just for you.</p>

            {/* Put this condition back when adding SAP */}
            {/* { invite && (
                <> */}
            <button onClick={start}>Begin</button>
            <img src={landingPageImage} alt="Landing page" />
            {/* </> 
              )} */}
          </div>
          <div className="signin-link">
            <a onClick={toSignIn} href="">
              Already have an account? Sign in
            </a>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  )
}
