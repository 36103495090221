import { combineReducers } from "redux"

import router from "./history"
import { authClient, client } from "./client"
import { resolve } from "./subscribers"

const specid = "3fa155b5-5dcc-4048-9ba2-9dbef17a3c4f"

function sleep(ms) {
  return new Promise((res) => setTimeout(res, ms))
}

export function wait() {
  return async (dispatch) => {
    await sleep(2000)
    return "done"
  }
}

function assessment(state = {}, action) {
  switch (action.type) {
    case "ASSESSMENT_NUMBER_SUCCESS":
      const { assessmentId, assessmentNumber } = action
      return {
        assessmentId,
        assessmentNumber,
      }
    default:
      return state
  }
}

export function assessmentStarted() {
  return async (dispatch, getState) => {
    const { assessment } = getState()
    const res = await client
      .takeAssessment({
        specid,
        assessmentid: assessment.assessmentId,
      })
      .catch((error) => {
        console.error("Error starting assessment", error)
        throw error
      })
    sessionStorage.setItem("participantID", res.participantId)
    sessionStorage.setItem("assessmentID", res.assessmentid)
    router.push(`/assessment/${res.assessmentid}`)
  }
}

export function setAssessmentNumber(assessmentId, assessmentNumber) {
  return {
    type: "ASSESSMENT_NUMBER_SUCCESS",
    assessmentId: assessmentId,
    assessmentNumber: assessmentNumber,
  }
}

export function guestLogin() {
  return async (dispatch, getState) => {
    const res = await authClient.guestLogin()
    dispatch(assessmentStarted())
  }
}

export function appleAuthSuccess(authorization) {
  return async (dispatch, getState) => {
    const { auth } = getState()
    const resp = await authClient.appleLogin(authorization, auth.mode, auth.transfer)

    if (resp.status === "no-account") {
      resolve("apple", resp)
    } else {
      const { history } = resp
      sessionStorage.setItem("authed", "true")
      dispatch({ type: "AUTH_SUCCESS", history })
      resolve("apple", history)
    }
  }
}

export function googleAuthSuccess(code) {
  return async (dispatch, getState) => {
    const { auth } = getState()
    const resp = await authClient.googleLogin(code, auth.mode, auth.transfer)

    if (resp.status === "no-account") {
      resolve("google", resp)
    } else {
      const { history } = resp
      sessionStorage.setItem("authed", "true")
      dispatch({ type: "AUTH_SUCCESS", history })
      resolve("google", history)
    }
  }
}

export function maybeGetHistory() {
  return async (dispatch, getState) => {
    const { auth } = getState()
    console.log("maybeGetHistory", auth)
    if (!auth.lastAssessment) {
      const history = await authClient.getHistory()
      dispatch({ type: "AUTH_SUCCESS", history })
    }
  }
}

function auth(state = {}, action) {
  switch (action.type) {
    case "AUTH_SUCCESS":
      const { history, token } = action
      return {
        ...history,
        token,
        authed: true,
      }
    case "GUEST_LOGIN_SUCCESS":
      return {
        token: action.token,
      }
    case "FETCH_TOKEN_SUCCESS":
      return {
        token: action.token,
      }
    case "GOOGLE_AUTH_START":
      return {
        ...state,
        mode: action.mode,
        transfer: action.transfer,
      }
    case "APPLE_AUTH_START":
      return {
        ...state,
        mode: action.mode,
        transfer: action.transfer,
      }
    default:
      return state
  }
}

function resumeModal(state = { shown: false, show: false, closed: false }, action) {
  console.log(action)
  switch (action.type) {
    //case "AUTH_SUCCESS":
    //  const { canResume } = action.history
    //  const show = canResume //&& !state.shown
    //  const shown = state.shown || show

    //  return {
    //    show,
    //    shown,
    //  }
    case "SHOW_RESUME_MODAL":
      return {
        show: true,
      }
    case "RESUME_MODAL_CLOSED":
      return {
        ...state,
        show: false,
      }
    default:
      return state
  }
}

function config(state = {}, action) {
  switch (action.type) {
    case "FETCH_CONFIG_SUCCESS":
      return action.config
    default:
      return state
  }
}

const rootReducer = combineReducers({
  auth,
  resumeModal,
  assessment,
  config,
})

export default rootReducer
