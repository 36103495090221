import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import { WarningModal } from './components/WarningModal';
import ModalContext from './modal'


export function useBlocker( blocker, when = true ) {
  const { navigator } = useContext( NavigationContext );

  useEffect( () => {
    if ( !when ) return;

    const unblock = navigator.block( ( tx ) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker( autoUnblockingTx );
    } );

    return () => {
      if(unblock) {
        unblock();
      }
    };
  }, [ navigator, blocker, when ] );
}


let skipNext = false

export function skipPrompt() {
  skipNext = true
}

export function usePrompt(msg, when, onEvent, location, blockBrowserBackButton=false) {
  const setModal = useContext(ModalContext)

  // Adding condition here to disable the browser back button in Order confirmation screen (checkout workflow) (Rock-73)
  // Note: we should avoid doing this as it's an anti-pattern and leads to poor user experience.
  const blocker = useCallback(
    (tx) => {
      if (skipNext && !blockBrowserBackButton) {
        skipNext = false
        tx.retry()
        return
      }

      if (tx.location.state?.shouldSkipPrompt && !blockBrowserBackButton) {
        tx.retry()
        return
      }
      
      const onClose = () => setModal()

      const onConfirm = () => {
        setModal()
        onEvent({type: "EXIT_CONFIRM"})
        tx.retry()
      }

      const handleLeave = () => {
        setModal()
        onEvent({type: "PURCHASE_SCREEN_DISPLAYED"})
        tx.retry()
      }

      const checkoutExitModal = () => (
        <WarningModal
          handleLeave={handleLeave}
          handleContinue={onClose}
          title="Exit Checkout"
          text="You are about to leave checkout. If you leave, your information may not be saved."
          btnLeaveText="Yes, Leave"
          btnContinueText="No, Continue"
        />
      )

      const assessmentExitModal = () => (
        <WarningModal
          handleLeave={onConfirm}
          handleContinue={onClose}
          title="Exit Health Survey"
          text="You are about to leave the health survey. If you leave, your information will not be saved. Are you sure you want to exit?"
          btnLeaveText="Leave"
          btnContinueText="Close"
        />
      )

      if (blockBrowserBackButton) {
        // Redirect users to the Welcome screen if clicking on the logo when in Order confirmation screen
        if(tx.location.state?.canNavigate) {
          onConfirm()
        }
      } else {

        if (location === "assessment") {
          setModal(assessmentExitModal)
        }
      
        if (location === "checkout") {
          setModal(checkoutExitModal)
        }
      }
    },
  [msg, location, blockBrowserBackButton]
  );

  useBlocker(blocker, when);
}
