
import "../css/WarningModal.css"


// Warning modal is displayed when the user clicks on either the logo or the browser back button.
export const WarningModal = ({ handleLeave, handleContinue, title, text, btnLeaveText, btnContinueText }) => {
  return (
    <main id="warning-modal"> 
      <section className="warning-modal-section">
        <div className="close-button-container">
          <i className="close-button-icon" onClick={handleContinue} role="button" aria-label="Close modal" />
        </div>

        { title && (<div className="warning-modal-title">{title}</div>) }
        <p className="warning-modal-text">{text}</p>
        <div className="warning-modal-buttons">
          <button
            className="warning-button"
            aria-label="Leave page"
            onClick={handleLeave}
          >
            {btnLeaveText}
          </button>
          <button
            className="warning-button"
            aria-label="Continue"
            onClick={e => handleContinue(e)}
          >
            {btnContinueText}
          </button>
        </div>
      </section>
    </main>
  )
}
